import Phaser from 'phaser'

export default class PrePreloader extends Phaser.Scene
{




	constructor()
	{
		super('prepreloader')
	}



	preload()
	{


		this.load.image('loadbar', 'ui/loadscreen-bar.png')
		this.load.image('knife', 'weapons/bullet.png')
		this.load.atlas('bull', 'character/bull.png', 'character/bull.json')
		//this.load.bitmapFont('SMALLRFUNI', 'fonts/smallrfun_0.png', 'fonts/smallrfuni.fnt');
		this.load.bitmapFont('RFUNI', 'fonts/bitrfun2_0.png', 'fonts/bitrfun2.fnt');
		



	}

	init(...params){
        console.info('INIT', JSON.stringify(params))
//		console.log("User is "+params[0].user+" Auth is "+params[0].auth);

    }

	create (...params)
	{
        console.info('CREATE', JSON.stringify(params))	
		this.scene.start('preloader')
	}
}