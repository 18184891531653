import Phaser from 'phaser'

import { sceneEvents } from '../events/EventsCenter'
import { gamescene,isMobile,joystickSide, map } from './Game';

export var joyStick;
export const OUTER_RADIUS=60;
const PADDING_X=20;
const PADDING_Y=20;
const INNER_RADIUS=30

export default class GameUI extends Phaser.Scene
{
	private hearts!: Phaser.GameObjects.Group
	private coinlabel!: Phaser.GameObjects.BitmapText
	private deltalabel!: Phaser.GameObjects.Text
	private caret!: Phaser.GameObjects.Image
	private alertcounter!: Phaser.GameObjects.Text
	private alertline2!: Phaser.GameObjects.Text
	private alertline1!: Phaser.GameObjects.Text
	private alertcountersecs!: Phaser.GameObjects.Text
	private alerticon!: Phaser.GameObjects.Image
	private alertrect!: Phaser.GameObjects.Rectangle
	private maprect!: Phaser.GameObjects.Rectangle
	private bailicon!: Phaser.GameObjects.Sprite
	private sideicon!: Phaser.GameObjects.Sprite
	private hslogo!: Phaser.GameObjects.Image


		
	joyStick;
	fireButton;
	text;

	initVal = 0.0


	msgleft
	msgright
	tw1
	tw2



	constructor()
	{
		super({ key: 'game-ui' })

	}

	loadFont(name, url) {
		var newFont = new FontFace(name, `url(${url})`);
		newFont.load().then(function (loaded) {
			document.fonts.add(loaded);
		}).catch(function (error) {
			return error;
		});
	}



	init ()
    {
        //  Inject our CSS
        const element = document.createElement('style');

        document.head.appendChild(element);

        const sheet = element.sheet;

        let styles = '@font-face { font-family: "RFUNI"; src: url("fonts/rfuni.ttf") format("truetype"); }\n';

        sheet.insertRule(styles, 0);

     }

    preload ()
    {


    }

	tweenComplete () {
		console.log("xxxxxx yes complete")
		
	}


	changeTexture(tween, target, key, current, previous, param) { 
		console.log("xxxxxxxx",key,  current, "   ",previous,"  ", param, "YYYY")
		if (current < 0) {
			target.setTexture('textanim','startmessagesetleft.png')
		}
		else {
		target.setTexture('textanim','startmessagesetright.png')
		}
	}

	create()
	{
//		this.loadFont('silkscreen','fonts/Silkscreen-Regular.ttf')

	const cw=this.sys.canvas.width;
	const ch=this.sys.canvas.height;


	var XSIDE = 132
	var YSIDE = 57

	var SCORE_PAD_X=0;
	var SCORE_PAD_Y=0;

	var TOP_BOX_Y=0;
	var TOP_BOX_X=0;
	var SCORE_DELTA_Y=0;

	if (isMobile)
	{
		SCORE_DELTA_Y=-2;
		SCORE_PAD_Y=18;
		SCORE_PAD_X=10;
		TOP_BOX_Y=36;
		TOP_BOX_X=10;

		var jx;

		console.log("[j] Joystick side is "+joystickSide+" "+isMobile);

		if (!joystickSide)
			jx=PADDING_X+OUTER_RADIUS;
		else
			jx=cw-(OUTER_RADIUS+PADDING_X);


	joyStick = this.plugins.get('rexvirtualjoystickplugin').add(this, {
	x: jx,
	y: (ch-PADDING_Y-OUTER_RADIUS),
	radius: OUTER_RADIUS,
	base: this.add.circle(0, 0, OUTER_RADIUS, 0x888888,0.4),
	thumb: this.add.circle(0, 0, INNER_RADIUS, 0xcccccc,0.8),
	// dir: '8dir',   // 'up&down'|0|'left&right'|1|'4dir'|2|'8dir'|3
	// forceMin: 16,
	// enable: true
})
/*.on('update', this.dumpJoyStickState, this);

this.text = this.add.text(600, 300,"HELLO WORLD");
this.dumpJoyStickState();*/



console.log("[j] got joystick "+joyStick);


/*	this.fireButton = this.add.circle(700, 250, 40, 0xffffff, 0.6);
	this.fireButton.setInteractive();
	this.fireButton.on('pointerdown', function() {
		if (gamescene!=undefined)
			{
				gamescene.setFire(true);
			}
			else
				console.log("[j] click no cursor");
		});
	this.fireButton.on('pointerup', function() {
		if (gamescene!=undefined)
			{
				gamescene.setFire(false);
			}
			else
				console.log("[j] release no cursor");
});*/


}
		
        WebFont.load({custom: { families: [ 'RFUNI' ]}});

		const ayd=ch-240;
		var bw,td;

		if (isMobile)
		{
			bw=400;
			td=0;
		}
		else
		{
			bw=cw;
			td=(cw-400)/2
		}
		const axd=((cw-bw)/2);

		//new minimap 
		
		this.maprect = this.add.rectangle(cw*0.71+XSIDE/2,ch*0.028+YSIDE/2,XSIDE,YSIDE,0x000000).setAlpha(0.6)

	
		// alert assets
		this.alertrect = this.add.rectangle(0+axd+bw/2,218+ayd,bw,45,0x000000).setAlpha(0.6)
		this.alertline1 = this.add.text(109+axd+td, 206+ayd, 'YOU ARE LEAVING THE GAME', {
			fontFamily: 'RFUNI', fontSize: 8, color:'yellow', stroke: '#000',
			strokeThickness: 1 
		})
		this.alertline2 = this.add.text(109+axd+td, 219+ayd, 'AVOID GETTING SHOT AND CANCELLING THE LEAVE TIMER', {
			fontFamily: 'RFUNI', fontSize: 8, color:'white', stroke: '#000',
			strokeThickness: 1 
		})
		this.alerticon = this.add.image(25+axd+td,220+ayd,'clockicon')
		this.alertcounter = this.add.text(47+axd+td, 204+ayd, '20', {
		
			fontFamily: 'RFUNI',  fontSize: 24, color:'red'
	   })
	   this.alertcountersecs = this.add.text(83+axd+td, 210+ayd, 's', {
		fontFamily: 'RFUNI',  fontSize: 18, color:'red'
   })
	
   	this.alertcounter.setVisible(false)
	this.alerticon.setVisible(false)
	this.alertline1.setVisible(false)
	this.alertline2.setVisible(false)
	this.alertrect.setVisible(false)
	this.alertcountersecs.setVisible(false)


   		var ry;
		if (isMobile)
			ry=ch*0.335
		else
			ry=ch*0.325;		

   		this.msgleft = this.add.image(200,ry, 'textanim','startmessagegetreadyleft.png')
		this.msgleft.setVisible(false)
		this.msgright = this.add.image(200,ry,'textanim','startmessagegetreadyright.png')
		this.msgright.setVisible(false)
		//this.add.image(200,180,'textanim','endmessageoverleft.png')
		//this.add.image(200,180,'textanim','endmessageoverright.png')
		

	
		this.add.rexRoundRectangle(56+TOP_BOX_X+SCORE_PAD_X, 28+TOP_BOX_Y-(SCORE_PAD_Y/2), 100+2*SCORE_PAD_X, 41+SCORE_PAD_Y, 3,0x000000, 0.5);
		this.add.rexRoundRectangle(56+TOP_BOX_X+SCORE_PAD_X, 62+TOP_BOX_Y, 100+2*SCORE_PAD_X, 23, 3,0x131a2d);

		this.add.image(21+TOP_BOX_X+SCORE_PAD_X, 20+TOP_BOX_Y+SCORE_DELTA_Y, 'bigg').setScale(1,1)


		this.caret = this.add.image(32+TOP_BOX_X+SCORE_PAD_X, 39+TOP_BOX_Y+SCORE_DELTA_Y, 'scorecaretup').setScale(1,1)
		this.caret.setVisible(true)

//		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		let { width, height } = this.sys.game.canvas;

		 if (isMobile) {

			const xx=(width*0.93);

			this.add.rexRoundRectangle(xx, height*0.05+height/8, 50, height/4, 3,0x000000, 0.3);


			this.sideicon = this.add.sprite(width*0.93, height*0.24, 'btnmobside').setScale(1,1).setInteractive()
			this.sideicon.setVisible(true)

			
			this.bailicon = this.add.sprite(width*0.93, height*0.12, 'btnmobbail').setScale(1,1).setInteractive()
			this.bailicon.setVisible(true)

			this.hslogo = this.add.image(66+SCORE_PAD_X, 24, 'hotshotmoblogo').setScale(1,1).setInteractive()
			this.hslogo.setVisible(true)

			this.sideicon.on('pointerdown', function ()
			{
				console.log("YYYYYY SIDE SLIDE pressed!!!!!!!")	
				gamescene.openCloseDrawer(1);
			});

			
			this.bailicon.on('pointerdown', function ()
			{
				if (gamescene.is_in_intro === false) gamescene.setLeaveTimer()
	
			});


		}

		
		const countdownLabel = this.add.text(12, 40, '', {
			 fontFamily: 'RFUNI',  fontSize: 32, color:'red'
		})

		this.coinlabel = this.add.bitmapText(28+TOP_BOX_X+SCORE_PAD_X, 10+TOP_BOX_Y+SCORE_DELTA_Y, 'RFUNI', '1234').setScale(1,1)
		//this.coinlabel.tint = 0xeeee00
		
		this.deltalabel = this.add.text(41+TOP_BOX_X+SCORE_PAD_X, 28+TOP_BOX_Y+SCORE_DELTA_Y, '0', {
			fontFamily: 'RFUNI', fontSize: 12, color:'white', stroke: '#000',
			strokeThickness: 3 
		})

		const gameoverLabel = this.add.text(12, 80, '', {
			 fontFamily: 'RFUNI',fontSize: 32, color:'red'
		})


		const leaveCountDownLabel = this.add.text(12, 90, '', {
			fontFamily: 'rfuni',fontSize: 32, color:'red'
		})

		const leaveOverLabel = this.add.text(12, 100, '', {
			fontFamily: 'rfuni', fontSize: 32, color:'red'
		})

		const progressg = this.add.graphics();
		const alienrain = this.add.graphics()


		sceneEvents.on('show-reloader-progress', (prog: number) => {
			progressg.clear();
			progressg.fillStyle(0x000000, 0.8);

			progressg.fillRect(9+TOP_BOX_X , 49+TOP_BOX_Y, 94+SCORE_PAD_X*2, 2); //13
			
			if (prog < 0.52) {
				progressg.fillStyle(0xff0000, 0.8);
			} else if (prog < 0.94) {
				progressg.fillStyle(0xffff00, 0.8);
			} else {
				progressg.fillStyle(0x00ff00, 0.8);
			}
			progressg.fillRect(9+TOP_BOX_X , 49+TOP_BOX_Y, (prog * (94+SCORE_PAD_X*2)), 2); //13
		})


		sceneEvents.on('draw-enemy-points',() => {
			alienrain.clear();
			alienrain.fillStyle(0xff0000, 0.8);
			let { width, height } = this.sys.game.canvas;
			
			// alienrain.fillRect(width*0.93, height*0.12 ,2,2); //13 


			gamescene.oplayers.getChildren().forEach(element  => {


				// if (element.visible) {
				// var xdelta = (element.x - gamescene.faune.x)/map.widthInPixels * XSIDE;
				// var ydelta = (element.y - gamescene.faune.y)/map.heightInPixels * YSIDE;

				// var checkyd = ydelta;
				// if (checkyd < 0) checkyd = -checkyd

				// var checkxd = xdelta;
				// if (checkxd < 0) checkxd = -checkxd

				// if (checkxd > checkyd) {
				// 	if (xdelta > 0) xdelta = XSIDE
				// 	else xdelta = 0
				// 	ydelta = ydelta + (YSIDE / 2)
				// 	if (ydelta > YSIDE) ydelta = YSIDE
				// 	if (ydelta < 0) ydelta = 0
				// } else {
				// 	if (ydelta > 0) ydelta = YSIDE
				// 	else ydelta = 0
				// 	xdelta = xdelta + (XSIDE / 2)
				// 	if (xdelta > XSIDE) xdelta = XSIDE
				// 	if (xdelta < 0) xdelta = 0
	
				// }

				// alienrain.fillRect(width*0.71+xdelta,height*0.028+ydelta,2,2);
				// }

				if (element.visible) {
				var xdelta = element.x/map.widthInPixels * XSIDE;
				var ydelta = element.y/map.heightInPixels * YSIDE;

				// var checkyd = ydelta;
				// if (checkyd < 0) checkyd = -checkyd

				// var checkxd = xdelta;
				// if (checkxd < 0) checkxd = -checkxd

				// if (checkxd > checkyd) {
				// 	if (xdelta > 0) xdelta = XSIDE
				// 	else xdelta = 0
				// 	ydelta = ydelta + (YSIDE / 2)
				// 	if (ydelta > YSIDE) ydelta = YSIDE
				// 	if (ydelta < 0) ydelta = 0
				// } else {
				// 	if (ydelta > 0) ydelta = YSIDE
				// 	else ydelta = 0
				// 	xdelta = xdelta + (XSIDE / 2)
				// 	if (xdelta > XSIDE) xdelta = XSIDE
				// 	if (xdelta < 0) xdelta = 0
	
				// }

				alienrain.fillRect(width*0.71+xdelta,height*0.028+ydelta,2,2);
				}


				
			})
			alienrain.fillStyle(0xffff00, 0.8);
			var xdelta = gamescene.faune.x/map.widthInPixels * XSIDE;
			var ydelta = gamescene.faune.y/map.heightInPixels * YSIDE;
			alienrain.fillRect(width*0.71+xdelta,height*0.028+ydelta,2,2);

			})


		sceneEvents.on('player-coins-initial', (coins: number) => {
		this.initVal = coins
		})


		sceneEvents.on('player-coins-changed', (coins: number) => {
			console.log("XXYY in teh change score")
			this.coinlabel.text =  /* 'ǃ' + */ Math.trunc(coins).toLocaleString()
			// coinsLabel.text = 'ǃ' + Math.trunc(coins).toLocaleString()
			this.coinlabel.tint = 0xeeee00
			// // coinsLabel.setText('ǃ' + Math.trunc(coins).toLocaleString())
			if (coins >= this.initVal) {
				this.caret.setTexture('scorecaretup')
				this.caret.setVisible(true)
				this.deltalabel.text = Math.trunc(coins-this.initVal).toLocaleString()
				this.deltalabel.setVisible(true)

			} 
			else if (coins < this.initVal) {
				this.caret.setTexture('scorecaretdown')
				this.caret.setVisible(true)
				this.deltalabel.text = Math.trunc(-(coins-this.initVal)).toLocaleString()
				this.deltalabel.setVisible(true)

			} 
			else {
				this.caret.setVisible(false)
				this.deltalabel.setVisible(false)

			}


		})






		sceneEvents.on('animate-big-msg', (msgnum: number) => {
			var txr,txm;
			if (isMobile)
			{
				txr=cw+50;
				txm=cw/2;
			}
			else
			{
				txr=cw+50;
				txm=cw/2;
			}

			// get ready set go
			if (msgnum === 1) {
		
			this.msgright.x = txr
			this.msgleft.x = -150
			//this.msgleft.setTexture('textanim','startmessagegetreadyleft.png')
			//this.msgright.setTexture('textanim','startmessagegetreadyright.png')
			this.msgleft.setVisible(true)
		//	this.msgright = this.add.image(200,120,'textanim','endmessageexitright.png')
			this.msgright.setVisible(true)
			// this.tw1 = this.tweens.add({
			// 	targets: this.msgleft,
			// 	x: 200,
			// 	duration: 500,
			// 	repeat: 3,
			// 	hold: 1500,
			// 	yoyo: false,
			// 	ease: 'Linear',
			// 	repeatDelay: 500,
			// 	// ease: 'circ.in',
			// 	onRepeat: this.changeTexture,
			// 	onComplete: this.tweenComplete
			// });

			// this.tw2 = this.tweens.add({
			// 	targets: this.msgright,
			// 	x: 200,
			// 	duration: 500,
			// 	repeat: 3,
			// 	hold: 1500,
			// 	yoyo: false,
				
			// 	repeatDelay: 500,
			// 	ease: 'Linear',
			// 	onRepeat: this.changeTexture,
			// 	onComplete: this.tweenComplete
			// });


			this.tw1 = this.tweens.chain({
				targets: this.msgright,
				tweens: [
					{
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear',
						yoyo: false
		
					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 0,
						duration: 0

					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 0,
						duration: 0,
						x: txr,
						ease: 'linear',
						repeat: 0,
						yoyo: false
			
					},
					{
						texture: ['textanim','startmessagesetright.png'],
						alpha: 1,
						duration: 0

					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 1,
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear'
					},
					{
						//texture: ['textanim','startmessagegoright.png'],
						alpha: 0,
						duration: 0
					},
					{
						alpha: 0,
						x: txr,
						duration: 0,
						ease: 'linear',
						repeat: 0,
						//texture: ['textanim','startmessagegoright.png']
					},
					{
						texture: ['textanim','startmessagegoright.png'],
						alpha: 1,
						duration: 0
					},

					{
						alpha: 1,
						//texture: ['textanim','startmessagegoright.png'],
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear'
		
					},
					{
						//texture: ['textanim','startmessagegoright.png'],
						alpha: 0,
						duration: 0
					},

					{
						x: txr,
						alpha: 0,
						duration: 0,
						ease: 'linear',
						repeat: 0,
						visible: false,
						//texture: ['textanim','startmessagegoright.png']
					}

				]
			});



			this.tw2 = this.tweens.chain({
				targets: this.msgleft,
				tweens: [
					{
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear',
						yoyo: false
		
					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 0,
						duration: 0

					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 0,
						duration: 0,
						x: -150,
						ease: 'linear',
						repeat: 0,
						yoyo: false
			
					},
					{
						texture: ['textanim','startmessagesetleft.png'],
						alpha: 1,
						duration: 0

					},
					{
						//texture: ['textanim','startmessagesetright.png'],
						alpha: 1,
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear'
					},
					{
						//texture: ['textanim','startmessagegoright.png'],
						alpha: 0,
						duration: 0
					},
					{
						alpha: 0,
						x: -150,
						duration: 0,
						ease: 'linear',
						repeat: 0,
						//texture: ['textanim','startmessagegoright.png']
					},
					{
						texture: ['textanim','startmessagegoleft.png'],
						alpha: 1,
						duration: 0
					},

					{
						alpha: 1,
						//texture: ['textanim','startmessagegoright.png'],
						x: txm,
						duration: 500,
						delay: 500,
						repeat: 0,
						hold: 1500,
						ease: 'linear'
		
					},
					{
						//texture: ['textanim','startmessagegoright.png'],
						alpha: 0,
						duration: 0
					},

					{
						x: -150,
						alpha: 0,
						duration: 0,
						ease: 'linear',
						repeat: 0,
						visible: false,
						//texture: ['textanim','startmessagegoright.png']
					}

				]
			});

		
		}
			
		else {


			var ry;
			if (isMobile)
				ry=ch*0.335
			else
				ry=ch*0.325;		
	

			this.msgright.x = txr
			this.msgleft.x = -150
			this.msgright.y = ry
			this.msgleft.y = ry

			//this.msgleft.setTexture('textanim','startmessagegetreadyleft.png')
			//this.msgright.setTexture('textanim','startmessagegetreadyright.png')
			this.msgleft.setVisible(true)
		//	this.msgright = this.add.image(200,120,'textanim','endmessageexitright.png')
			this.msgright.setVisible(true)
			// game exit
			if (msgnum === 2) {
				console.log("dtdt IN GAME EXIT")
				this.tw1 = this.tweens.chain({
					targets: this.msgright,
					tweens: [
						{
							alpha: 1,
							duration: 0,
							visible: true
						},
						{
							texture: ['textanim','endmessageexitright.png'],
							alpha: 1,
							duration: 0
	
						},
						{
							alpha: 1,
							x: txm,
							duration: 500,
							delay: 500,
							repeat: 0,
							hold: 1500,
							ease: 'linear',
							yoyo: false
				
						}
	
					]
				});
	
				this.tw2 = this.tweens.chain({
					targets: this.msgleft,
					tweens: [
						{
							alpha: 0,
							duration: 0,
							visible: true
	
						},
						{
							texture: ['textanim','endmessageexitleft.png'],
							alpha: 1,
							duration: 0
	
						},
						{
							alpha: 1,
							x: txm,
							duration: 500,
							delay: 500,
							repeat: 0,
							hold: 1500,
							ease: 'linear',
							yoyo: false
				
						}
	
					]
				});


			}
			// game over
			if (msgnum === 3) {


				this.tw1 = this.tweens.chain({
					targets: this.msgright,
					tweens: [
						{
							alpha: 0,
							duration: 0
	
						},
						{
							texture: ['textanim','endmessageoverright.png'],
							alpha: 1,
							duration: 0
	
						},
						{
							alpha: 1,
							x: txm,
							duration: 500,
							delay: 500,
							repeat: 0,
							hold: 1500,
							ease: 'linear',
							yoyo: false
				
						}
	
					]
				});
	
				this.tw2 = this.tweens.chain({
					targets: this.msgleft,
					tweens: [
						{
							alpha: 0,
							duration: 0
	
						},
						{
							texture: ['textanim','endmessageoverleft.png'],
							alpha: 1,
							duration: 0
	
						},
						{
							alpha: 1,
							x: txm,
							duration: 500,
							delay: 500,
							repeat: 0,
							hold: 1500,
							ease: 'linear',
							yoyo: false
				
						}
	
					]
				});


			}
		}





		})



		sceneEvents.on('player-deathtimer-changed', (secs: number) => {
			// coinsLabel.text = 'ANG$' + coins.toLocaleString()
			if (secs > 0) {
				this.alertcounter.text = secs.toLocaleString()
				this.alertcounter.setColor('red')
				this.alertcountersecs.setColor('red')
				this.alertline1.text = 'TOKEN BALANCE IS TOO LOW'
				this.alertline1.setColor('red')
				this.alertline2.text = 'Shoot a player to remain in the game'
				this.alerticon.setTexture('crosshairicon')
				this.alertcounter.setVisible(true)
				this.alerticon.setVisible(true)
				this.alertline1.setVisible(true)
				this.alertline2.setVisible(true)
				this.alertrect.setVisible(true)
				this.alertcountersecs.setVisible(true)
				}
			else {
				leaveCountDownLabel.text = ''
				this.alertcounter.setVisible(false)
				this.alerticon.setVisible(false)
				this.alertline1.setVisible(false)
				this.alertline2.setVisible(false)
				this.alertrect.setVisible(false)
				this.alertcountersecs.setVisible(false)
			}
	})


		sceneEvents.on('player-leavetimer-changed', (secs: number) => {
			// coinsLabel.text = 'ANG$' + coins.toLocaleString()
			if (secs > 0) { 
			// leaveCountDownLabel.text = 'LEAVING GAME IN ' + secs.toLocaleString() + " SECS"
			this.alertcounter.text = secs.toLocaleString()
			this.alertcounter.setColor('white')
			this.alertcountersecs.setColor('white')
			this.alertline1.text = 'YOU WILL LEAVE THE GAME WHEN THE TIMER EXPIRES'
			this.alertline1.setColor('yellow')
			this.alertline2.text = 'LEAVING will be cancelled IF YOU ARE HIT BY A BULLET'
			this.alerticon.setTexture('clockicon')
			this.alertcounter.setVisible(true)
			this.alerticon.setVisible(true)
			this.alertline1.setVisible(true)
			this.alertline2.setVisible(true)
			this.alertrect.setVisible(true)
			this.alertcountersecs.setVisible(true)
			}
			else {
			leaveCountDownLabel.text = ''
			this.alertcounter.setVisible(false)
			this.alerticon.setVisible(false)
			this.alertline1.setVisible(false)
			this.alertline2.setVisible(false)
			this.alertrect.setVisible(false)
			this.alertcountersecs.setVisible(false)

			}
		})


		sceneEvents.on('player-leaveover', (show: boolean) => {
				// coinsLabel.text = 'ANG$' + coins.toLocaleString()
				if (show) 
				leaveOverLabel.text = 'GAME OVER, MATE!'
				else
				leaveOverLabel.text = ''
	
		})


		sceneEvents.on('player-gameover', (show: boolean) => {
			// coinsLabel.text = 'ANG$' + coins.toLocaleString()
			if (show) 
			gameoverLabel.text = 'GAME OVER, MATE!'
			else
			gameoverLabel.text = ''

		})

		this.hearts = this.add.group({
			classType: Phaser.GameObjects.Image
		})

		this.hearts.createMultiple({
			key: 'scorebullet',
			setXY: {
				x: 34+TOP_BOX_X+SCORE_PAD_X,
				y: 62+TOP_BOX_Y, //52
				stepX: 24
			},
			quantity: 3
		})

		sceneEvents.on('player-bullets-changed', this.handlePlayerHealthChanged, this)

		this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
			console.log("XXYY")
			sceneEvents.off('player-health-changed', this.handlePlayerHealthChanged, this)
			sceneEvents.off('player-coins-changed')
		})
	}

	private handlePlayerHealthChanged(bullets: number)
	{
		this.hearts.children.each((go, idx) => {
			const heart = go as Phaser.GameObjects.Image
			if ((2-idx) >= bullets)
			{
				heart.setTexture('scorebullet')
			}
			else
			{
				heart.setTexture('scorebulletdark')
			}
		})
	}
}
