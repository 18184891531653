import Phaser from 'phaser'

const createCharacterAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'faune-idle-down',
		frames: [{ key: 'faune', frame: 'walk-down-3.png' }]
	})

	anims.create({
		key: 'faune-idle-up',
		frames: [{ key: 'faune', frame: 'walk-up-3.png' }]
	})

	anims.create({
		key: 'faune-idle-side',
		frames: [{ key: 'faune', frame: 'walk-side-3.png' }]
	})

	anims.create({
		key: 'faune-run-down',
		frames: anims.generateFrameNames('faune', { start: 1, end: 8, prefix: 'run-down-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'faune-run-up',
		frames: anims.generateFrameNames('faune', { start: 1, end: 8, prefix: 'run-up-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'faune-run-side',
		frames: anims.generateFrameNames('faune', { start: 1, end: 8, prefix: 'run-side-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'faune-faint',
		frames: anims.generateFrameNames('faune', { start: 1, end: 4, prefix: 'faint-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterAnims
}



const createCharacterPrezzaAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'prezza-idle-down',
		frames: [{ key: 'prezza', frame: 'Prezza-Front-3.png' }]
	})

	anims.create({
		key: 'prezza-idle-up',
		frames: [{ key: 'prezza', frame: 'Prezza-Back-3.png' }]
	})

	anims.create({
		key: 'prezza-idle-side',
		frames: [{ key: 'prezza', frame: 'Prezza-Right-3.png' }]
	})

	anims.create({
		key: 'prezza-run-down',
		frames: anims.generateFrameNames('prezza', { start: 1, end: 5, prefix: 'Prezza-Front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'prezza-run-up',
		frames: anims.generateFrameNames('prezza', { start: 1, end: 5, prefix: 'Prezza-Back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'prezza-run-side',
		frames: anims.generateFrameNames('prezza', { start: 1, end: 5, prefix: 'Prezza-Right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'prezza-faint',
		frames: anims.generateFrameNames('prezza', { start: 1, end: 5, prefix: 'Prezza-Left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterPrezzaAnims
}


const createCharacterCarabinieriAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'carabinieri-idle-down',
		frames: [{ key: 'carabinieri', frame: 'carabinieri-front-3.png' }]
	})

	anims.create({
		key: 'carabinieri-idle-up',
		frames: [{ key: 'carabinieri', frame: 'carabinieri-back-3.png' }]
	})

	anims.create({
		key: 'carabinieri-idle-side',
		frames: [{ key: 'carabinieri', frame: 'carabinieri-right-3.png' }]
	})

	anims.create({
		key: 'carabinieri-run-down',
		frames: anims.generateFrameNames('carabinieri', { start: 1, end: 5, prefix: 'carabinieri-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'carabinieri-run-up',
		frames: anims.generateFrameNames('carabinieri', { start: 1, end: 5, prefix: 'carabinieri-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'carabinieri-run-side',
		frames: anims.generateFrameNames('carabinieri', { start: 1, end: 5, prefix: 'carabinieri-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'carabinieri-faint',
		frames: anims.generateFrameNames('carabinieri', { start: 1, end: 5, prefix: 'carabinieri-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterCarabinieriAnims
}



const createCharacterPdogeAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'pdoge-idle-down',
		frames: [{ key: 'pdoge', frame: 'pdoge-front-3.png' }]
	})

	anims.create({
		key: 'pdoge-idle-up',
		frames: [{ key: 'pdoge', frame: 'pdoge-back-3.png' }]
	})

	anims.create({
		key: 'pdoge-idle-side',
		frames: [{ key: 'pdoge', frame: 'pdoge-right-3.png' }]
	})

	anims.create({
		key: 'pdoge-run-down',
		frames: anims.generateFrameNames('pdoge', { start: 1, end: 5, prefix: 'pdoge-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'pdoge-run-up',
		frames: anims.generateFrameNames('pdoge', { start: 1, end: 5, prefix: 'pdoge-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'pdoge-run-side',
		frames: anims.generateFrameNames('pdoge', { start: 1, end: 5, prefix: 'pdoge-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'pdoge-faint',
		frames: anims.generateFrameNames('pdoge', { start: 1, end: 5, prefix: 'pdoge-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterPdogeAnims
}



const createCharacterApeAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'ape-idle-down',
		frames: [{ key: 'ape', frame: 'ape-front-3.png' }]
	})

	anims.create({
		key: 'ape-idle-up',
		frames: [{ key: 'ape', frame: 'ape-back-3.png' }]
	})

	anims.create({
		key: 'ape-idle-side',
		frames: [{ key: 'ape', frame: 'ape-right-3.png' }]
	})

	anims.create({
		key: 'ape-run-down',
		frames: anims.generateFrameNames('ape', { start: 1, end: 5, prefix: 'ape-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ape-run-up',
		frames: anims.generateFrameNames('ape', { start: 1, end: 5, prefix: 'ape-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ape-run-side',
		frames: anims.generateFrameNames('ape', { start: 1, end: 5, prefix: 'ape-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ape-faint',
		frames: anims.generateFrameNames('ape', { start: 1, end: 5, prefix: 'ape-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterApeAnims
}



const createCharacterWizardAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'wizard-idle-down',
		frames: [{ key: 'wizard', frame: 'wizard-front-3.png' }]
	})

	anims.create({
		key: 'wizard-idle-up',
		frames: [{ key: 'wizard', frame: 'wizard-back-3.png' }]
	})

	anims.create({
		key: 'wizard-idle-side',
		frames: [{ key: 'wizard', frame: 'wizard-right-3.png' }]
	})

	anims.create({
		key: 'wizard-run-down',
		frames: anims.generateFrameNames('wizard', { start: 1, end: 5, prefix: 'wizard-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'wizard-run-up',
		frames: anims.generateFrameNames('wizard', { start: 1, end: 5, prefix: 'wizard-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'wizard-run-side',
		frames: anims.generateFrameNames('wizard', { start: 1, end: 5, prefix: 'wizard-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'wizard-faint',
		frames: anims.generateFrameNames('wizard', { start: 1, end: 5, prefix: 'wizard-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterWizardAnims
}


const createCharacterHedgefundAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'hedgefund-idle-down',
		frames: [{ key: 'hedgefund', frame: 'hedgefund-front-1.png' }]
	})

	anims.create({
		key: 'hedgefund-idle-up',
		frames: [{ key: 'hedgefund', frame: 'hedgefund-back-1.png' }]
	})

	anims.create({
		key: 'hedgefund-idle-side',
		frames: [{ key: 'hedgefund', frame: 'hedgefund-right-1.png' }]
	})

	anims.create({
		key: 'hedgefund-run-down',
		frames: anims.generateFrameNames('hedgefund', { start: 1, end: 5, prefix: 'hedgefund-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'hedgefund-run-up',
		frames: anims.generateFrameNames('hedgefund', { start: 1, end: 5, prefix: 'hedgefund-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'hedgefund-run-side',
		frames: anims.generateFrameNames('hedgefund', { start: 1, end: 5, prefix: 'hedgefund-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'hedgefund-faint',
		frames: anims.generateFrameNames('hedgefund', { start: 1, end: 5, prefix: 'hedgefund-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterHedgefundAnims
}



const createCharacterBearAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'bear-idle-down',
		frames: [{ key: 'bear', frame: 'bear-front-3.png' }]
	})

	anims.create({
		key: 'bear-idle-up',
		frames: [{ key: 'bear', frame: 'bear-back-3.png' }]
	})

	anims.create({
		key: 'bear-idle-side',
		frames: [{ key: 'bear', frame: 'bear-right-3.png' }]
	})

	anims.create({
		key: 'bear-run-down',
		frames: anims.generateFrameNames('bear', { start: 1, end: 5, prefix: 'bear-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bear-run-up',
		frames: anims.generateFrameNames('bear', { start: 1, end: 5, prefix: 'bear-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bear-run-side',
		frames: anims.generateFrameNames('bear', { start: 1, end: 5, prefix: 'bear-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bear-faint',
		frames: anims.generateFrameNames('bear', { start: 1, end: 5, prefix: 'bear-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterBearAnims
}



const createCharacterBullAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'bull-idle-down',
		frames: [{ key: 'bull', frame: 'bull-front-3.png' }]
	})

	anims.create({
		key: 'bull-idle-up',
		frames: [{ key: 'bull', frame: 'bull-back-3.png' }]
	})

	anims.create({
		key: 'bull-idle-side',
		frames: [{ key: 'bull', frame: 'bull-right-3.png' }]
	})

	anims.create({
		key: 'bull-run-down',
		frames: anims.generateFrameNames('bull', { start: 1, end: 5, prefix: 'bull-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bull-run-up',
		frames: anims.generateFrameNames('bull', { start: 1, end: 5, prefix: 'bull-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bull-run-side',
		frames: anims.generateFrameNames('bull', { start: 1, end: 5, prefix: 'bull-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bull-faint',
		frames: anims.generateFrameNames('bull', { start: 1, end: 5, prefix: 'bull-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterBullAnims
}


const createCharacterAshiAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'ashi-idle-down',
		frames: [{ key: 'ashi', frame: 'ashi-front-3.png' }]
	})

	anims.create({
		key: 'ashi-idle-up',
		frames: [{ key: 'ashi', frame: 'ashi-back-3.png' }]
	})

	anims.create({
		key: 'ashi-idle-side',
		frames: [{ key: 'ashi', frame: 'ashi-right-3.png' }]
	})

	anims.create({
		key: 'ashi-run-down',
		frames: anims.generateFrameNames('ashi', { start: 1, end: 5, prefix: 'ashi-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ashi-run-up',
		frames: anims.generateFrameNames('ashi', { start: 1, end: 5, prefix: 'ashi-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ashi-run-side',
		frames: anims.generateFrameNames('ashi', { start: 1, end: 5, prefix: 'ashi-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ashi-faint',
		frames: anims.generateFrameNames('ashi', { start: 1, end: 5, prefix: 'ashi-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterAshiAnims
}



const createCharacterNinjaAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'ninja-idle-down',
		frames: [{ key: 'ninja', frame: 'ninja-front-3.png' }]
	})

	anims.create({
		key: 'ninja-idle-up',
		frames: [{ key: 'ninja', frame: 'ninja-back-3.png' }]
	})

	anims.create({
		key: 'ninja-idle-side',
		frames: [{ key: 'ninja', frame: 'ninja-right-3.png' }]
	})

	anims.create({
		key: 'ninja-run-down',
		frames: anims.generateFrameNames('ninja', { start: 1, end: 5, prefix: 'ninja-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ninja-run-up',
		frames: anims.generateFrameNames('ninja', { start: 1, end: 5, prefix: 'ninja-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ninja-run-side',
		frames: anims.generateFrameNames('ninja', { start: 1, end: 5, prefix: 'ninja-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'ninja-faint',
		frames: anims.generateFrameNames('ninja', { start: 1, end: 5, prefix: 'ninja-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterNinjaAnims
}



const createCharacterKaratekaAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'karateka-idle-down',
		frames: [{ key: 'karateka', frame: 'karateka-front-3.png' }]
	})

	anims.create({
		key: 'karateka-idle-up',
		frames: [{ key: 'karateka', frame: 'karateka-back-3.png' }]
	})

	anims.create({
		key: 'karateka-idle-side',
		frames: [{ key: 'karateka', frame: 'karateka-right-3.png' }]
	})

	anims.create({
		key: 'karateka-run-down',
		frames: anims.generateFrameNames('karateka', { start: 1, end: 5, prefix: 'karateka-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'karateka-run-up',
		frames: anims.generateFrameNames('karateka', { start: 1, end: 5, prefix: 'karateka-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'karateka-run-side',
		frames: anims.generateFrameNames('karateka', { start: 1, end: 5, prefix: 'karateka-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'karateka-faint',
		frames: anims.generateFrameNames('karateka', { start: 1, end: 5, prefix: 'karateka-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterKaratekaAnims
}




const createCharacterBdsmAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'bdsm-idle-down',
		frames: [{ key: 'bdsm', frame: 'bdsm-front-3.png' }]
	})

	anims.create({
		key: 'bdsm-idle-up',
		frames: [{ key: 'bdsm', frame: 'bdsm-back-3.png' }]
	})

	anims.create({
		key: 'bdsm-idle-side',
		frames: [{ key: 'bdsm', frame: 'bdsm-right-3.png' }]
	})

	anims.create({
		key: 'bdsm-run-down',
		frames: anims.generateFrameNames('bdsm', { start: 1, end: 5, prefix: 'bdsm-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bdsm-run-up',
		frames: anims.generateFrameNames('bdsm', { start: 1, end: 5, prefix: 'bdsm-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bdsm-run-side',
		frames: anims.generateFrameNames('bdsm', { start: 1, end: 5, prefix: 'bdsm-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'bdsm-faint',
		frames: anims.generateFrameNames('bdsm', { start: 1, end: 5, prefix: 'bdsm-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterBdsmAnims
}


// this.load.atlas('amazonian', 'character/amazonian.png', 'character/amazonian.json')

const createCharacterAmazonianAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'amazonian-idle-down',
		frames: [{ key: 'amazonian', frame: 'amazonian-front-3.png' }]
	})

	anims.create({
		key: 'amazonian-idle-up',
		frames: [{ key: 'amazonian', frame: 'amazonian-back-3.png' }]
	})

	anims.create({
		key: 'amazonian-idle-side',
		frames: [{ key: 'amazonian', frame: 'amazonian-right-3.png' }]
	})

	anims.create({
		key: 'amazonian-run-down',
		frames: anims.generateFrameNames('amazonian', { start: 1, end: 5, prefix: 'amazonian-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'amazonian-run-up',
		frames: anims.generateFrameNames('amazonian', { start: 1, end: 5, prefix: 'amazonian-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'amazonian-run-side',
		frames: anims.generateFrameNames('amazonian', { start: 1, end: 5, prefix: 'amazonian-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'amazonian-faint',
		frames: anims.generateFrameNames('amazonian', { start: 1, end: 5, prefix: 'amazonian-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterAmazonianAnims
}


// this.load.atlas('area51', 'character/area51.png', 'character/area51.json')


const createCharacterArea51Anims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'area51-idle-down',
		frames: [{ key: 'area51', frame: 'area51-front-3.png' }]
	})

	anims.create({
		key: 'area51-idle-up',
		frames: [{ key: 'area51', frame: 'area51-back-3.png' }]
	})

	anims.create({
		key: 'area51-idle-side',
		frames: [{ key: 'area51', frame: 'area51-right-3.png' }]
	})

	anims.create({
		key: 'area51-run-down',
		frames: anims.generateFrameNames('area51', { start: 1, end: 5, prefix: 'area51-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'area51-run-up',
		frames: anims.generateFrameNames('area51', { start: 1, end: 5, prefix: 'area51-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'area51-run-side',
		frames: anims.generateFrameNames('area51', { start: 1, end: 5, prefix: 'area51-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'area51-faint',
		frames: anims.generateFrameNames('area51', { start: 1, end: 5, prefix: 'area51-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterArea51Anims
}


// this.load.atlas('banana', 'character/banana.png', 'character/banana.json')


const createCharacterBananaAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'banana-idle-down',
		frames: [{ key: 'banana', frame: 'banana-front-1.png' }]
	})

	anims.create({
		key: 'banana-idle-up',
		frames: [{ key: 'banana', frame: 'banana-back-1.png' }]
	})

	anims.create({
		key: 'banana-idle-side',
		frames: [{ key: 'banana', frame: 'banana-left-5.png' }]
	})

	anims.create({
		key: 'banana-run-down',
		frames: anims.generateFrameNames('banana', { start: 1, end: 5, prefix: 'banana-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'banana-run-up',
		frames: anims.generateFrameNames('banana', { start: 1, end: 5, prefix: 'banana-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'banana-run-side',
		frames: anims.generateFrameNames('banana', { start: 1, end: 5, prefix: 'banana-left-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'banana-faint',
		frames: anims.generateFrameNames('banana', { start: 1, end: 5, prefix: 'banana-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterBananaAnims
}


// this.load.atlas('dragon', 'character/dragon.png', 'character/dragon.json')

const createCharacterDragonAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'dragon-idle-down',
		frames: [{ key: 'dragon', frame: 'dragon-front-3.png' }]
	})

	anims.create({
		key: 'dragon-idle-up',
		frames: [{ key: 'dragon', frame: 'dragon-back-3.png' }]
	})

	anims.create({
		key: 'dragon-idle-side',
		frames: [{ key: 'dragon', frame: 'dragon-right-3.png' }]
	})

	anims.create({
		key: 'dragon-run-down',
		frames: anims.generateFrameNames('dragon', { start: 1, end: 5, prefix: 'dragon-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'dragon-run-up',
		frames: anims.generateFrameNames('dragon', { start: 1, end: 5, prefix: 'dragon-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'dragon-run-side',
		frames: anims.generateFrameNames('dragon', { start: 1, end: 5, prefix: 'dragon-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'dragon-faint',
		frames: anims.generateFrameNames('dragon', { start: 1, end: 5, prefix: 'dragon-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterDragonAnims
}


// this.load.atlas('eagle', 'character/eagle.png', 'character/eagle.json')

const createCharacterEagleAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'eagle-idle-down',
		frames: [{ key: 'eagle', frame: 'eagle-front-3.png' }]
	})

	anims.create({
		key: 'eagle-idle-up',
		frames: [{ key: 'eagle', frame: 'eagle-back-3.png' }]
	})

	anims.create({
		key: 'eagle-idle-side',
		frames: [{ key: 'eagle', frame: 'eagle-right-3.png' }]
	})

	anims.create({
		key: 'eagle-run-down',
		frames: anims.generateFrameNames('eagle', { start: 1, end: 5, prefix: 'eagle-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'eagle-run-up',
		frames: anims.generateFrameNames('eagle', { start: 1, end: 5, prefix: 'eagle-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'eagle-run-side',
		frames: anims.generateFrameNames('eagle', { start: 1, end: 5, prefix: 'eagle-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'eagle-faint',
		frames: anims.generateFrameNames('eagle', { start: 1, end: 5, prefix: 'eagle-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterEagleAnims
}

// this.load.atlas('knight', 'character/knight.png', 'character/knight.json')

const createCharacterKnightAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'knight-idle-down',
		frames: [{ key: 'knight', frame: 'knight-front-3.png' }]
	})

	anims.create({
		key: 'knight-idle-up',
		frames: [{ key: 'knight', frame: 'knight-back-3.png' }]
	})

	anims.create({
		key: 'knight-idle-side',
		frames: [{ key: 'knight', frame: 'knight-right-3.png' }]
	})

	anims.create({
		key: 'knight-run-down',
		frames: anims.generateFrameNames('knight', { start: 1, end: 5, prefix: 'knight-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'knight-run-up',
		frames: anims.generateFrameNames('knight', { start: 1, end: 5, prefix: 'knight-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'knight-run-side',
		frames: anims.generateFrameNames('knight', { start: 1, end: 5, prefix: 'knight-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'knight-faint',
		frames: anims.generateFrameNames('knight', { start: 1, end: 5, prefix: 'knight-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterKnightAnims
}

// this.load.atlas('spaceman', 'character/spaceman.png', 'character/spaceman.json')

const createCharacterSpacemanAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'spaceman-idle-down',
		frames: [{ key: 'spaceman', frame: 'spaceman-front-1.png' }]
	})

	anims.create({
		key: 'spaceman-idle-up',
		frames: [{ key: 'spaceman', frame: 'spaceman-back-1.png' }]
	})

	anims.create({
		key: 'spaceman-idle-side',
		frames: [{ key: 'spaceman', frame: 'spaceman-right-1.png' }]
	})

	anims.create({
		key: 'spaceman-run-down',
		frames: anims.generateFrameNames('spaceman', { start: 1, end: 5, prefix: 'spaceman-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'spaceman-run-up',
		frames: anims.generateFrameNames('spaceman', { start: 1, end: 5, prefix: 'spaceman-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'spaceman-run-side',
		frames: anims.generateFrameNames('spaceman', { start: 1, end: 5, prefix: 'spaceman-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'spaceman-faint',
		frames: anims.generateFrameNames('spaceman', { start: 1, end: 5, prefix: 'spaceman-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterSpacemanAnims
}

// this.load.atlas('tiger', 'character/tiger.png', 'character/tiger.json')

const createCharacterTigerAnims = (anims: Phaser.Animations.AnimationManager) => {
	anims.create({
		key: 'tiger-idle-down',
		frames: [{ key: 'tiger', frame: 'tiger-front-3.png' }]
	})

	anims.create({
		key: 'tiger-idle-up',
		frames: [{ key: 'tiger', frame: 'tiger-back-3.png' }]
	})

	anims.create({
		key: 'tiger-idle-side',
		frames: [{ key: 'tiger', frame: 'tiger-right-3.png' }]
	})

	anims.create({
		key: 'tiger-run-down',
		frames: anims.generateFrameNames('tiger', { start: 1, end: 5, prefix: 'tiger-front-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'tiger-run-up',
		frames: anims.generateFrameNames('tiger', { start: 1, end: 5, prefix: 'tiger-back-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'tiger-run-side',
		frames: anims.generateFrameNames('tiger', { start: 1, end: 5, prefix: 'tiger-right-', suffix: '.png' }),
		repeat: -1,
		frameRate: 15
	})

	anims.create({
		key: 'tiger-faint',
		frames: anims.generateFrameNames('tiger', { start: 1, end: 5, prefix: 'tiger-left-', suffix: '.png' }),
		frameRate: 15
	})
}

export {
	createCharacterTigerAnims
}

