import { GameObjects, Scene } from 'phaser';

export class Text extends GameObjects.BitmapText {
  constructor(scene: Scene, x: number, y: number, text: string) {
    super(scene, x, y, 'SMALLRFUNI', text);
    this.setOrigin(0, 0)
    this.setTint(0xffff00)
    this.setScale(1,1)
    scene.add.existing(this);
  }
}


// export class Text extends GameObjects.Text {
//   constructor(scene: Scene, x: number, y: number, text: string) {
//     super(scene, x, y, text, {
//       fontSize: 6,
//       color: '#fff',
//       stroke: '#000',
//       strokeThickness: 2,
//     });

//     this.setOrigin(0, 0);

//     scene.add.existing(this);
//   }

// }