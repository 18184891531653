import Phaser from 'phaser'


import Preloader from './scenes/Preloader'
import Game from './scenes/Game'
import GameUI from './scenes/GameUI'
import PrePreloader from './scenes/prepreloader'


/*export default new Phaser.Game({
  type: Phaser.AUTO,
  width: 400,
  height: 240,
  physics: {
      default: 'arcade',
      arcade: {
          gravity: { y: 0 },
          debug: false
      }
  },
  scene: [Preloader, Game, GameUI],
  scale: {
      zoom: 2
  }
 })*/


var config = {
    type: Phaser.AUTO,
    width: 400,
    height: 240,
    physics: {
        default: 'arcade',
        input :{
            activePointers:3,
          },
        arcade: {
            gravity: { y: 0 },
            debug: false
        }
    },
    scale: {
        zoom: 2
    }


}; 

export var gamegl;

import { gamescene } from "./scenes/Game"

Log("Game started");

parent.addEventListener('message', function (e) {
    Log("data  has properties ["+Object.getOwnPropertyNames(e.data)+"]");
    handleMessage(e);
});

window.addEventListener('message', function (e) {
    Log(" wm data  has properties ["+Object.getOwnPropertyNames(e.data)+"]");
    handleMessage(e);
});


parent.postMessage({type: 'pp-var2', params: "started" }, "*");

Log("Sending start message");


function handleMessage(e:MessageEvent)
{
    if (e.data.type === 'pp-var') {

        config.width=e.data.width;
        config.height=e.data.height;
 
        Log("Got parameter "+e.data.session_id+" "+e.data.ip+" "+e.data.port+" "+e.data.hyatt_secret+" "+e.data.infra_secret+" >>"+e.data.character_type+" ["+e.data.map_type+"] pid "+e.data.playground_instance_id+" "+e.data.musicvolume+" "+e.data.sfxvolume);

        
        startGame(e);


    }
    else if (e.data.type=="pp-vol")
    {
        Log("Got vol "+e.data.audio_type+" "+e.data.volume+" "+e.data.type+" "+gamescene);

        gamescene.setVolume(e.data.audio_type,e.data.volume);
    }
    else if (e.data.type=="pp-leave")
    {
     
    if (gamescene.is_in_intro == false) gamescene.setLeaveTimer();
    }

}

function startGame(e:MessageEvent)
{
    gamegl = new Phaser.Game(config);
    gamegl.scene.add('prepreloader', PrePreloader, true, {user:e.data.session_id, auth:e.data.infra_secret});
    gamegl.scene.add('preloader', Preloader, false, {user:e.data.session_id, auth:e.data.infra_secret});
    gamegl.scene.add('game', Game, false, { session:e.data.session_id,hyatt_secret:e.data.hyatt_secret, infra_secret:e.data.infra_secret, character_type:e.data.character_type, map_type:e.data.map_type, pid:e.data.playground_instance_id, ip:e.data.ip, port:e.data.port, musicvolume:e.data.musicvolume, sfxvolume:e.data.sfxvolume, key_defs:e.data.key_defs,key_on:e.data.key_on, custom_flag:e.data.custom_flag });
    gamegl.scene.add('game-ui', GameUI, false, {user:'test', auth:'test-auth'});
    

}

function Log(message)
{
    console.log("[g] "+message);
}