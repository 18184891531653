import Phaser from 'phaser'

export default class Preloader extends Phaser.Scene
{

	private loadbull!: Phaser.Physics.Arcade.Sprite
	private bullet!: Phaser.GameObjects.Image
	private loadbar!: Phaser.GameObjects.Image
	private loadingLabel!: Phaser.GameObjects.BitmapText


	constructor()
	{
		super('preloader')
	}

	loadFont(name, url) {
		var newFont = new FontFace(name, `url(${url})`);
		newFont.load().then(function (loaded) {
			document.fonts.add(loaded);
		}).catch(function (error) {
			return error;
		});
	}

	preload()
	{
		const progress = this.add.graphics();

		let { width, height } = this.sys.game.canvas;

        this.load.on('progress', value =>
        {
			this.bullet.setX(value*width-4).setScale(2,2);
			this.loadbar.setX(value*width-(this.loadbar.width)).setScale(2,2);

        });

        this.load.on('complete', () =>
        {

            progress.destroy();

        });

		// this.load.image('tiles', 'tiles/dungeon_tiles_extruded.png')
		// this.load.tilemapTiledJSON('dungeon', 'tiles/dungeon-01.json')

		//this.load.image('Big Ben', 'tiles/Big Ben.png')
		//this.load.image('Bridge-Westminster-Section', 'tiles/Bridge-Westminster-Section.png')
		// this.load.image('London Assets No Wording', 'tiles/London Assets No Wording.png')
		//this.load.image('Water', 'tiles/Water.png')
		//this.load.image('TowerBridge', 'tiles/TowerBridge.png')

		// lon, zag, nyc, tok, kal
//		const mapType = "lon"




		// this.load.image('loadbar', 'ui/loadscreen-bar.png')
		// this.load.image('knife', 'weapons/bullet.png')
		// this.load.atlas('bull', 'character/bull.png', 'character/bull.json')

		// this.load.start()
		
		this.loadbull = this.add.sprite(width/2,height*0.4,'bull').setScale(4,4)
		//this.loadbull.setX(this.loadbull.x-this.loadbull.width/4)
		this.loadbull.setY(this.loadbull.y-this.loadbull.height/2)
		

		this.anims.create({
			key: 'bull-load-side',
			frames: this.anims.generateFrameNames('bull', { start: 1, end: 5, prefix: 'bull-right-', suffix: '.png' }),
			repeat: -1,
			frameRate: 15
		})
		this.loadbull.play('bull-load-side')

		this.loadbar = this.add.image(0,height*0.8, 'loadbar')
		//this.loadbar.setY(height*0.7-this.loadbar.height/2)
		this.bullet = this.add.image(10,height*0.8, 'knife')
		//this.bullet.setY(height*0.7-this.bullet.height/2)

		this.loadingLabel = this.add.bitmapText(width/2, height*0.55, 'RFUNI', 'loading...').setScale(1,1)
		this.loadingLabel.setX(-this.loadingLabel.width/2+width/2)
	    

		//this.coinlabel.tint = 0xeeee00


//		if (mapType == "lon") {
		this.load.image('tiles-london', 'tiles/city-london.png')
		this.load.tilemapTiledJSON('city-london', 'tiles/city-london.json')
//		}

//		if (mapType == "zag") {
		this.load.image('tiles-zagreb', 'tiles/city-zagreb.png')
		this.load.tilemapTiledJSON('city-zagreb', 'tiles/city-zagreb.json')
//		}

//		if (mapType == "nyc") {
		this.load.image('tiles-nyc', 'tiles/city-nyc.png')
		this.load.tilemapTiledJSON('city-nyc', 'tiles/city-nyc.json')

//		if (mapType == "tok") {
		this.load.image('tiles-tokyo', 'tiles/city-tokyo.png')
		this.load.tilemapTiledJSON('city-tokyo', 'tiles/city-tokyo.json')
//		}

//		if (mapType == "kal") {
		this.load.image('tiles-kalevala', 'tiles/city-kalevala.png')
		this.load.tilemapTiledJSON('city-kalevala', 'tiles/city-kalevala.json')
//		}

        this.load.plugin('rexoutlinepipelineplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexoutlinepipelineplugin.min.js', true);


		var url= 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexvirtualjoystickplugin.min.js';
        this.load.plugin('rexvirtualjoystickplugin', url, true);

		this.load.atlas('prezza', 'character/Prezza.png', 'character/Prezza.json')
		this.load.atlas('carabinieri', 'character/carabinieri.png', 'character/carabinieri.json')
		this.load.atlas('pdoge', 'character/pdoge.png', 'character/pdoge.json')
		this.load.atlas('hedgefund', 'character/hedgefund.png', 'character/hedgefund.json')
		this.load.atlas('ape', 'character/ape.png', 'character/ape.json')
		this.load.atlas('ashi', 'character/ashi.png', 'character/ashi.json')
		this.load.atlas('bear', 'character/bear.png', 'character/bear.json')
		this.load.atlas('bdsm', 'character/bdsm.png', 'character/bdsm.json')
//		this.load.atlas('bull', 'character/bull.png', 'character/bull.json')
		this.load.atlas('wizard', 'character/wizard.png', 'character/wizard.json')
		this.load.atlas('karateka', 'character/karateka.png', 'character/karateka.json')
		this.load.atlas('ninja', 'character/ninja.png', 'character/ninja.json')
		this.load.atlas('amazonian', 'character/amazonian.png', 'character/amazonian.json')
		this.load.atlas('area51', 'character/area51.png', 'character/area51.json')
		this.load.atlas('banana', 'character/banana.png', 'character/banana.json')
		this.load.atlas('dragon', 'character/dragon.png', 'character/dragon.json')
		this.load.atlas('eagle', 'character/eagle.png', 'character/eagle.json')
		this.load.atlas('knight', 'character/knight.png', 'character/knight.json')
		this.load.atlas('spaceman', 'character/spaceman.png', 'character/spaceman.json')
		this.load.atlas('tiger', 'character/tiger.png', 'character/tiger.json')
		this.load.atlas('flagsheet', 'tiles/flagsheet.png', 'tiles/flagsheet.json')
		this.load.atlas('textanim', 'ui/textanim.png', 'ui/textanim.json')
		// this.load.atlas('teleports', 'tiles/teleports-tileset.png', 'tiles/teleports-tileset.json')
		this.load.atlas('teleports', 'tiles/tele-nyc-glow.png', 'tiles/tele-nyc-glow.json')
		this.load.atlas('teleportsnyc', 'tiles/tele-nyc-glow.png', 'tiles/tele-nyc-glow.json')
		this.load.atlas('teleportstok', 'tiles/tele-tky-animation.png', 'tiles/tele-tky-animation.json')
		this.load.atlas('teleportslon', 'tiles/tele-ldn-glow.png', 'tiles/tele-ldn-glow.json')
		this.load.atlas('teleportszag', 'tiles/tele-zgb-glow.png', 'tiles/tele-zgb-glow.json')
		this.load.atlas('teleportskal', 'tiles/tele-kal-animation.png', 'tiles/tele-kal-animation.json')


		this.load.atlas('bpglow', 'tiles/BailOut-PortalGlow.png', 'tiles/BailOut-PortalGlow.json')
		this.load.atlas('bpgend', 'tiles/bailoutportalglowend.png', 'tiles/bailoutportalglowend.json')
		this.load.atlas('pjoin', 'tiles/portaljoin.png', 'tiles/portaljoin.json')
		

		this.load.atlas('lizard', 'enemies/lizard.png', 'enemies/lizard.json')
		this.load.atlas('treasure', 'items/treasure.png', 'items/treasure.json')

		this.load.image('ui-heart-empty', 'ui/ui_heart_empty.png')
		this.load.image('ui-heart-full', 'ui/ui_heart_full.png')
		this.load.image('bigg', 'ui/currency-g.png')
		this.load.image('scorebullet', 'ui/pic-bullet.png')
		this.load.image('scorebulletdark', 'ui/pic-bullet-dark.png')
		this.load.image('scorecaretup', 'ui/caret-up.png')
		this.load.image('scorecaretdown', 'ui/caret-down.png')
		this.load.image('crosshairicon', 'ui/pixelart-crosshair-red.png')
		this.load.image('clockicon', 'ui/pixelart-clock.png')
		this.load.image('flag-gb', 'selection/flags/gb.png')

		// new icons for mobile game
		this.load.image('hotshotmoblogo', 'ui/hotshotmoblogo.png')
		this.load.image('btnmobbail', 'ui/btnmobbail.png')
		this.load.image('btnmobside', 'ui/btnmobside.png')


		this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
		//this.load.bitmapFont('RFUNI', 'fonts/bitrfun2_0.png', 'fonts/bitrfun2.fnt');
		this.load.bitmapFont('SMALLRFUNI', 'fonts/smallrfun_0.png', 'fonts/smallrfuni.fnt');
		this.load.plugin('rexroundrectangleplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexroundrectangleplugin.min.js', true);  

		this.loadFont('rfuni','rfuni.ttf')

		this.load.audio('theme-lon', ['audio/99-retro-nobass.mp3']);
		this.load.audio('theme-zag', ['audio/06-happymisso.mp3']);
		this.load.audio('theme-tok', ['audio/04-sandy.mp3']);
		this.load.audio('theme-kal', ['audio/03-gojira-final.mp3']);
		this.load.audio('theme-nyc', ['audio/05-lil-killa.mp3']);

		this.load.audio('sound-getready', ['audio/hotshot-get-ready.mp3']);
		this.load.audio('sound-set', ['audio/hotshot-set.mp3']);
		this.load.audio('sound-go', ['audio/hotshot-go.mp3']);

		
			

		this.load.audio('csnd', [
            'audio/countalert.mp3'
        ]);

		this.load.audio('shotsnd', [
            'audio/laser1.mp3'
        ]);

		this.load.audio('deathsnd', [
            'audio/dallaend.mp3'
        ]);


		this.load.audio('hitsnd1', [
            'audio/hitsnd1.wav'
        ]);
		this.load.audio('hitsnd2', [
            'audio/hitsnd2.wav'
        ]);
		this.load.audio('hitsnd3', [
            'audio/hitsnd3.wav'
        ]);
		this.load.audio('hitsnd4', [
            'audio/hitsnd4.wav'
        ]);
		this.load.audio('hitsnd5', [
            'audio/hitsnd5.wav'
        ]);
		this.load.audio('hitsnd6', [
            'audio/hitsnd6.wav'
        ]);
		this.load.audio('hitsnd7', [
            'audio/hitsnd7.wav'
        ]);
		this.load.audio('hitsnd8', [
            'audio/hitsnd8.wav'
        ]);
		this.load.audio('hitsnd9', [
            'audio/hitsnd9.wav'
        ]);



	}

	init(...params){
        console.info('INIT', JSON.stringify(params))
//		console.log("User is "+params[0].user+" Auth is "+params[0].auth);

    }

	create (...params)
	{
        console.info('CREATE', JSON.stringify(params))	
		this.scene.start('game')
	}
}