import Phaser, { GameObjects, Input } from 'phaser'

import { debugDraw } from '../utils/debug'
import { createLizardAnims } from '../anims/EnemyAnims'
import { createOplayerAnims } from '../anims/EnemyAnims'
import { createCharacterAnims, createCharacterCarabinieriAnims, createCharacterBdsmAnims ,createCharacterPdogeAnims ,createCharacterKaratekaAnims,createCharacterNinjaAnims, createCharacterHedgefundAnims,createCharacterWizardAnims,createCharacterAshiAnims,createCharacterBearAnims,createCharacterBullAnims, createCharacterApeAnims, createCharacterAmazonianAnims, createCharacterArea51Anims, createCharacterBananaAnims, createCharacterDragonAnims, createCharacterEagleAnims, createCharacterKnightAnims, createCharacterSpacemanAnims, createCharacterTigerAnims} from '../anims/CharacterAnims'
import { createCharacterPrezzaAnims } from '../anims/CharacterAnims'
import { createCharacterCarabinieriAnims } from '../anims/CharacterAnims'
import { createChestAnims } from '../anims/TreasureAnims'

import Lizard from '../enemies/Lizard'
import Oplayer from '../enemies/Oplayer'

import '../characters/Faune'


import Faune from '../characters/Faune'

import { sceneEvents } from '../events/EventsCenter'
import Chest from '../items/Chest'

export var isMobile;
export var gamescene;
export var cursors2;
export var joystickSide;
export var map;
import { gamegl } from "main"
import { joyStick } from './GameUI'
import {OUTER_RADIUS} from './GameUI'

import { NibbleWidthError } from 'web3'
import { flattenToSet } from '~/node_modulesx/@babel/core/lib/config/helpers/deep-array'

const MAX_ZOOM:number =5;
const MIN_ZOOM:number =0.25;
const ZOOM_STEP=0.05;

const SPECIAL_SID="aaaaaaaaaa0000000000aaaaaaaaaa0000000000";

export default class Game extends Phaser.Scene {
	private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
	private cursors2!: Phaser.Types.Input.Keyboard.CursorKeys
	private faune!: Faune

	private knives!: Phaser.Physics.Arcade.Group
	private eneknives!: Phaser.Physics.Arcade.Group
	private oplayers!: Phaser.Physics.Arcade.Group
	private lizards!: Phaser.Physics.Arcade.Group
	private chests!: Phaser.Physics.Arcade.StaticGroup

	private playerLizardsCollider?: Phaser.Physics.Arcade.Collider

	private timedEvent
	private leaveEvent
	

	private sprogTimer!: Phaser.Time.TimerEvent

	joystickSide=false;
	isClicking=false;
	ignoreClick=false;
	firePointer:Input.Pointer | null=null;	
	canvasWidth: number=0;
	canvasHeight:number=0;

	joyPosYTop:number=0;
	joyPosYBottom:number=0;
	
	joyPosXLeft:number=0;
	joyPosXRight:number=0;
	joyFireLeft:number=0;
	joyFireRight:number=0;

	isSpecial: number=0;

	trackPlayer:number=-1;
	trackAlias:String;

	sprogress
	firstTimeBalance = 0
	firstTele: number = 0
	noMove: number = 0
	
	no_shoot_end = false
	is_in_intro = false

	isMobile=false
	

	deathtimeron = false
	leavetimeron = false
    gameoverFlag = false
	lastt = 0.0
	myCharacterSkin = Math.floor(Math.random() * 10) + 1;

	mapType = "lon"   // lon, nyc, zag, kal , tok

	drawgraph 

	static LEAVE_TIMER = 10
	static DIS_TIMER = 1
	static INITIAL_WAIT_TIME = 8000


	// private players = XPlayer[];


//	static GAME_SERVER_URL = "ws://localhost:8080/ws";
	static MAX_PLAYERS = 40
	static MAX_BULLETS_PER_PLAYER = 3
	
	// 1 = just hit , 2 = bounce until obstruction or timer
	static HIT_OPTION = 2
	
	// static GAME_SERVER_URL = "ws://172.105.245.64:5255/ws";

	// leave
	static STATE_START_LEAVING = 101
	static STATE_STOP_LEAVING = 102
	static STATE_FINAL_LEAVE_ANIM_START = 103
	static STATE_LEAVE_END = 104
	// teleport
	static STATE_TELEPORT_START = 105
	static STATE_TELEPORT_FINAL_ANIM = 106
	static STATE_TELEPORT_END = 107



	portIp="172.105.245.64:5255";
	hyatt_secret="";
	infra_secret="";
	sessionId:String="";
	customFlag:String="";
	music;
	postFxPlugin;


	sfxVolume:number=0.5;
	musicVolume:number=0.5;
	static KEY1_UP =0;
	static KEY1_LEFT =1;
	static KEY1_DOWN =2;
	static KEY1_RIGHT =3;
	static KEY1_FIRE =4;
	static KEY1_LEAVE =5;
	static KEY2_UP =6;
	static KEY2_LEFT =7;
	static KEY2_DOWN =8;
	static KEY2_RIGHT =9;
	static KEY2_FIRE =10;
	static KEY2_LEAVE =11;
	key_defs:number[]=[	];
	key_on:number=1;
	tracking: Phaser.Physics.Arcade.Sprite;
	camera:Phaser.Cameras.Scene2D.Camera;

	constructor() {
		super('game')
	}

	preload() {


		this.input.addPointer(3);

		this.graphics = this.add.graphics({ lineStyle: { width: 2, color: 0x00ff00 }, fillStyle: { color: 0xff0000 }});


		this.postFxPlugin = this.plugins.get('rexoutlinepipelineplugin');
		console.log("[g] preload "+this.key_defs.length);
		this.cursors = this.input.keyboard.createCursorKeys()
		if (this.key_defs.length!=0 && (this.key_on!=0))
		{
/*			this.cursors.up.keyCode=this.key_defs[Game.KEY1_UP];
			this.cursors.down.keyCode=this.key_defs[Game.KEY1_DOWN];
			this.cursors.left.keyCode=this.key_defs[Game.KEY1_LEFT];
			this.cursors.right.keyCode=this.key_defs[Game.KEY1_RIGHT];
			this.cursors.space.keyCode=this.key_defs[Game.KEY1_FIRE];*/
			
			if (this.key_on==1)
				this.cursors=this.makeKeyCursor(0);
			else if (this.key_on==2)
				this.cursors=this.makeKeyCursor(6);
			else
			{
				this.cursors=this.makeKeyCursor(6);
				if (!isMobile)
				this.cursors2=this.makeKeyCursor(0);

			}

//			joyStick.on('update', this.dumpJoyStickState, this);


			console.log("[j] cursor2  is "+this.cursors2+" "+isMobile);
		}

		gamescene=this;
		parent.postMessage({type: 'pp-var2', params: "ingame" }, "*");
	}

	makeKeyCursor(offset:number)
	{
		return this.input.keyboard.addKeys({ 'up': this.key_defs[Game.KEY1_UP+offset], 'down': this.key_defs[Game.KEY1_DOWN+offset],'left':this.key_defs[Game.KEY1_LEFT+offset],'right':this.key_defs[Game.KEY1_RIGHT+offset],'space':this.key_defs[Game.KEY1_FIRE+offset] });		
	}

	connSetup() {
		//setup server connection
		let self = this
		//self.conn = new WebSocket("ws://localhost:8080/ws")
		// self.conn = new WebSocket("ws://192.168.1.7:8080/ws")
		// self.conn = new WebSocket("ws://192.168.8.124:8000/ws")
		self.conn = new WebSocket("wss://"+this.portIp+"/ws?s="+this.sessionId+"&h="+this.hyatt_secret+"&i="+this.infra_secret+"&c="+this.myCharacterSkin+"&f="+this.customFlag)
		
		// self.conn = new WebSocket("ws://127.0.0.1:8000/ws")

		//self.conn = new WebSocket("ws://172.19.249.154:8080/ws")

		// 172.19.249.154
		//self.conn = new WebSocket("ws://10.206.5.3:8080/ws")
		// self.conn = new WebSocket("ws://192.168.0.101:8080/ws")
		
		self.conn.binaryType = 'arraybuffer'

		// connection lost
		self.conn.onclose = () => parent.postMessage({type: 'pp-var2', params: "endgame",status:-1 }, "*"); // -1 Internet disconnection (not sure what reason) / -2 disconnected by night watchman

		// the data is coming in
		self.conn.onmessage = function (evt) {
			// console.log("Incoming data packet....")
			let b = new Uint8Array(evt.data)
			let commandType = b[0]
			// var ct = 0;
			// ct = commandType
			// console.log("cmdt" + ct)
			switch (commandType) {
				case CommandFromServer.INIT_PLAYER:
					console.log("SRVPKT: INIT_PLAYER")
					MyPlayer.ID = Util.bytesToInt32(b[1], b[2], b[3], b[4]);
					MyPlayer.Flag = String.fromCharCode(Util.bytesToInt8(b[5]),Util.bytesToInt8(b[6]))
					if (self.customFlag?.length > 0) MyPlayer.Flag=self.customFlag;
					console.log("Player ID: " + MyPlayer.ID)
					console.log("Player flag code: " + MyPlayer.Flag)
					self.faune.setFlag(MyPlayer.Flag)
					const barr = new Uint8Array(b.slice(7))
					const decoder = new TextDecoder();
					const strx = decoder.decode(barr);
					console.log("YYY Player Alias: " + strx)
					self.faune.setAlias(strx)
					
					sceneEvents.emit('animate-big-msg', 1 )
					self.requestToJoin()
					console.log("sent request to join")
					self.is_in_intro = true
					self.time.delayedCall(900, self.playGetReady, [1], self)
					self.time.delayedCall(3700, self.playSet, [1], self)
					self.time.delayedCall(6500, self.playGo, [1], self)
					self.time.delayedCall(7000, self.introEnd, [1], self)
					self.sendXY()
					break;

				case CommandFromServer.PLAYERS_POSITIONS:
					console.log("SRVPKT: PLAYER_POSITIONS")
					self.updatePositions(
						Helper.byteMessageToPlayersInfo(b)
					);
					break;



				case CommandFromServer.ENEMY_INFO:
					console.log("SRVPKT: ENEMY_INFO")
					const eID = Util.bytesToInt32(b[1], b[2], b[3], b[4]);
					const eFlag = String.fromCharCode(Util.bytesToInt8(b[5]),Util.bytesToInt8(b[6]))
					console.log("Enemy ID: " + this.eID)
					console.log("Enemy flag code: " + this.eFlag)

					const barr2 = new Uint8Array(b.slice(7)) // XXXX was 8 in Mark's code 
					const decoder2 = new TextDecoder();
					const strx2 = decoder2.decode(barr2);
					console.log("YYY Player Alias: " + strx2)

					const ar: GameObjects = self.oplayers.getMatching('pid', eID)
					//const ar: GameObjects = this.oplayers.getMatching('name', player.id)
					const ji = ar.length
					console.log("MATCHINGXXXXXXXXXXXX ****  = " + ji)
					if (ji > 0) {	
						ar[0].setFlag(eFlag)
						ar[0].setAlias(strx2)
						console.log("MATCHINGXXXXXXXXXXXX flag  = " + eFlag + " alias"+ strx2)

					}

					break;

				case CommandFromServer.EXIT_STATE_INFORM:
					console.log("X**** SRVPKT: EXIT_STATE_INFORM")
					let enidx = Util.bytesToInt32(b[1], b[2], b[3], b[4]);
					let exitmode = Util.bytesToInt8(b[5]);
					console.log("X**** enemy exit mode",enidx,exitmode)
					self.enemyGoExitMode(enidx,exitmode)
					break;


				case CommandFromServer.NEW_ENEMY_ENTRY:
					console.log("SRVPKT: NEW_ENEMY_ENTRY (dep)")
										
					let cidx = Util.bytesToInt32(b[1], b[2], b[3], b[4]);
					let chtype = Util.bytesToInt16(b[5],b[6]);
					console.log("XXX new enemy",cidx,chtype)	
					self.enemyEntryCharInfo(cidx, chtype)
					break;
	
					case CommandFromServer.REMOVE_ENEMY:
						console.log("SRVPKT: **** REMOVE ENEMY or MYPLAYER")					
						let cid = Util.bytesToInt32(b[1], b[2], b[3], b[4]);
						if (cid == MyPlayer.ID) {
							console.log("**** remove my player - instruction from server",cid)	
							self.removeMyPlayer()
	
						} else  {
						console.log("**** remove enemy",cid)	
						self.removeEnemyPlayer(cid)
						}
						break;
			
				

				case CommandFromServer.NEW_BALANCE:
				console.log("SRVPKT: NEW_BALANCE (dep)")

				const newbalance: number  = Util.bytesToInt32(b[1], b[2], b[3], b[4])

					console.log("YYYYY in here 1 ")
					console.log("New Token balance is "+ newbalance)
			        // sceneEvents.emit('player-coins-changed', newbalance )		
					//self.cntSoundPlay()
					break

					case CommandFromServer.NEW_BALANCE_FLOAT:
						console.log("SRVPKT: NEW_BALANCE_FLOAT")

					// testing new balance
						const newbalancef: number  = Util.bytesToInt32(b[1], b[2], b[3], b[4])/100;
						//newbalancef = newbalancef/100
						console.log("YYYYY in here 2 ")
						
						if (self.firstTimeBalance === 0) {
							sceneEvents.emit('player-coins-initial', newbalancef )
							self.firstTimeBalance = 1
							}
	
						console.log("dtdt New Token balance is "+ newbalancef)
						sceneEvents.emit('player-coins-changed', newbalancef )
						//this.faune.
						self.faune.setOverhead("ǃ"+Math.trunc(newbalancef).toLocaleString())
						//text=Math.trunc(newbalancef).toLocaleString	
						console.log("dtdt New Token balance is "+ self.gameoverFlag + " and dea")
						if (!self.gameoverFlag) {					
						// new to show death timer and sound
						// if (newbalancef < 0.000001) {
						if (newbalancef < 1) {

							console.log("dtdt In new balance"+ newbalancef + " dton=" + self.deathtimeron)

						if (!self.deathtimeron) {
								self.deathtimeron = true
								console.log("dtdt in here 2")

								if (typeof self.timedEvent == 'undefined') {
									self.timedEvent = self.time.addEvent({ delay: 1000, callback: self.onDeathCountdown, callbackScope: self, repeat: 10})
									console.log("dtdt in here 3xxxx")
								}
									else { 
									self.timedEvent.reset({ delay: 1000, callback: self.onDeathCountdown, callbackScope: self, repeat: 10})		
									//self.cntSoundPlay()
									console.log("dtdt in here 4ssss")
									}
							}
						else {
							// really dead
							console.log("XXXX User really dead")
							self.timedEvent.reset()
							sceneEvents.emit('player-deathtimer-changed', -1)
							// sceneEvents.emit('player-gameover', true)
							self.gameOver()
							}


						}
						else {
							console.log("dtdt In ear "+ newbalancef)
							// solve this below XXX
						    if (self.timedEvent!= undefined && self.timedEvent.paused === false) { 
							console.log("dtdt Cancel timer here "+ newbalancef)
							self.timedEvent.reset();
							sceneEvents.emit('player-deathtimer-changed', -1)
							// = self.time.addEvent({ delay: 1000, callback: self.onDeathCountdown, callbackScope: self, repeat: 10},self.timedEvent )
							//self.timedEvent.paused = true;
							//self.timedevent.repeat = false;
							self.deathtimeron = false
							}

						}
					}

						//self.cntSoundPlay()
						// sceneEvents.emit('player-deathtimer-changed', 5 )
					    
						break
						
	

				case CommandFromServer.NEW_BULLET:
					console.log("SRVPKT: NEW_BULLET")

					 console.log("New Bullet received")
					// bx = Util.bytesToInt16(b[1], b[2]);
					// by = Util.bytesToInt16(b[3], b[4]);

					// vx = Util.bytesToInt16(b[5], b[6]);
					// vy = Util.bytesToInt16(b[7], b[8]);
					self.updateWithNewBullet(
						Helper.byteMessageToNewBulletInfo( b)
					);
					break;

			}

		}
	}


	introEnd() {
		this.is_in_intro = false
	}

	onDeathCountdown() {
		this.cntSoundPlay();
		console.log("xxxx " + this.timedEvent.repeatCount)
		
		sceneEvents.emit('player-deathtimer-changed', this.timedEvent.repeatCount)
		if (this.timedEvent.repeatCount === 0) { 
			// really dead
			this.timedEvent.reset()
			this.timedEvent.remove()
			sceneEvents.emit('player-deathtimer-changed', -1)
			// XXX sceneEvents.emit('player-gameover', true)
			this.gameOver()

		}
	}


	setLeaveTimer() {
		
		if (isMobile)
			this.ignoreClick=true;        

		if (!this.leavetimeron) {
			this.leavetimeron = true
		


			if ((typeof this.leaveEvent == 'undefined') || (this.leaveEvent == null)) {
				
				this.leaveEvent = this.time.addEvent({ delay: 1000, callback: this.onLeaveCountdown, callbackScope: this, repeat: (Game.LEAVE_TIMER)})
				
				console.log("xxxx ADD Leave Timer" + this.leaveEvent.repeatCount)
			
			}
				else {
//				this.leaveEvent.reset({ delay: 1000, callback: this.onLeaveCountdown, callbackScope: this, repeat: (Game.LEAVE_TIMER), paused: false})	
				
				this.leaveEvent.reset({
					delay: 1000,                // ms
					callback: this.onLeaveCountdown,
					callbackScope: this,
					loop: false,
					repeat: (Game.LEAVE_TIMER),
					startAt: 0,
					timeScale: 1,
					paused: false
				})
				this.time.addEvent(this.leaveEvent);
				
				console.log("xxxx RESET Leave Timer" + this.leaveEvent.repeatCount)	
			}
				//self.cntSoundPlay()
			this.leaveEvent.repeatCount = 10
			
			this.cntSoundPlay();
			this.faune.setBPGlow();
			this.sendPreEnd(Game.STATE_START_LEAVING)
			sceneEvents.emit('player-leavetimer-changed', this.leaveEvent.repeatCount)
			}
	}

 
	onLeaveCountdown() {
		if (this.leaveEvent.repeatCount === 0) { 
			// really dead
			// this.leaveEvent.reset()
		
			this.no_shoot_end = true
			this.sendPreEnd(Game.STATE_LEAVE_END)
			
			this.leaveEvent.remove()
			
			sceneEvents.emit('player-leavetimer-changed', 0)
			// sceneEvents.emit('player-leaveover', true)

			this.leavetimeron = false
			console.log("Leave exit 1 noshoot set")
			sceneEvents.emit('animate-big-msg', 2 )
	
			//this.sendEnd(1)
			this.time.delayedCall(4000, this.leaveOver2, [1], this)
			//this.gameOver()


		} else {

	    if (this.leaveEvent.repeatCount == 2) {
		this.no_shoot_end = true
		
		this.sendPreEnd(Game.STATE_FINAL_LEAVE_ANIM_START)
		this.faune.setBPGEnd();
		this.time.addEvent({ delay: 250, callback: this.onFauneDis, callbackScope: this, repeat: 0})
		}
		this.cntSoundPlay();
		
		
		sceneEvents.emit('player-leavetimer-changed', this.leaveEvent.repeatCount-1)
		}
	}




	leaveOver2() {
		console.log("dtdt leave exit")
		this.sendEnd(1)

	}






	onFauneDis() {
		this.noMove = 1
		//`this.faune.balVal.setVisible(false)
		if (this.faune.head_up_option  == 2) {
		this.faune.hudFlag.setVisible(false)
		this.faune.nameText.setVisible(false)
		}
		else if (this.faune.head_up_option  == 1) {
			this.faune.balVal.setVisible(false)
			}
		//this.faune.destroy()
		this.faune.setVisible(false)
		this.faune.setActive(false)
	}


	onFauneTeleDis() {
		console.log("Teleport exit")
		this.sendPreEnd(Game.STATE_TELEPORT_FINAL_ANIM)
		//`this.faune.balVal.setVisible(false)
		if (this.faune.head_up_option  == 2) {
			this.faune.hudFlag.setVisible(false)
			this.faune.nameText.setVisible(false)
			}
			else if (this.faune.head_up_option  == 1) {
				this.faune.balVal.setVisible(false)
				}
			//this.faune.destroy()
		this.faune.setVisible(false)
		this.faune.setActive(false)

		//this.sendEnd(1)

	}
	onFauneTeleDis2() {
		this.sendPreEnd(Game.STATE_TELEPORT_END)
		console.log("Teleport exit2")
		this.sendEnd(1)

	}
	
	onFauneApp() {
		
		//`this.faune.balVal.setVisible(false)
		if (this.faune.head_up_option  == 2) {
			this.faune.hudFlag.setVisible(true)
			this.faune.nameText.setVisible(true)
			}
			else if (this.faune.head_up_option  == 1) {
				this.faune.balVal.setVisible(true)
				}
			//this.faune.destroy()
		this.faune.setVisible(true)
	}


	// player is completely dead - exit game
	// this is after x seconds of time to regain some tokens
	gameOver() {
		this.timedEvent.reset();
		this.deathtimeron = false;
		// play full derath sound
		// old message sceneEvents.emit('player-gameover', true)
		this.playSfx(this.deathSound)
		this.gameoverFlag = true
		this.faune.setFauneDead()
		console.log("Killed exit 1")
		this.time.delayedCall(4000, this.gameOver2, [1], this)
		sceneEvents.emit('animate-big-msg', 3 )
	}

	gameOver2() {
		console.log("dtdt Killed exit")
		this.sendEnd(0)

	}

	public setVolume(type:string, volume:number)
	{
		console.log("[g] IN GAME got volume "+volume+" for "+type);

		if (type=="musicvolume")
		{
			var vv=volume/10;
			this.musicVolume=vv;
			console.log("[g] Setting music volume "+vv);
			this.music.setVolume(vv);
		}
		else if (type=="sfxvolume")
		{
			this.sfxVolume=volume/10;
			console.log("[g] Setting sfx volume "+this.sfxVolume);
		}
	}

/*	sendEnd(stat)
	{

		this.conn.close();
		parent.postMessage({type: 'pp-var2', params: "endgame",status:stat }, "*");
				
		console.log("Closing game");
		console.log("Closing "+gamegl);

		gamegl.destroy(true,false);

	}*/

	displayEnd(stat)
    {


        // let bytearray = new Uint8Array(2);
        // bytearray[0] = CommandToServer.END_GAME_NOW
        // bytearray[1] = (stat & 255)
        // // 
        // if (this.conn.readyState === WebSocket.OPEN) {
        //     this.conn.send(bytearray.buffer)
        //     console.log("sending end game now")
        // }



        setTimeout(function()
         {
            self.conn.close();
         },9000);


        
        parent.postMessage({type: 'pp-var2', params: "endgame",status:stat }, "*");
                
        console.log("Closing game");
        console.log("Closing "+gamegl);


        
        //XXX gamegl.destroy(true,false);
        




    }


	sendPreEnd(stat)
    {


        let bytearray = new Uint8Array(2);
        bytearray[0] = CommandToServer.END_GAME_NOW
        bytearray[1] = (stat & 255)
        // 
        if (this.conn.readyState === WebSocket.OPEN) {
            this.conn.send(bytearray.buffer)
            console.log("sending end game now")
        }
	}


	sendEnd(stat)
    {


        let bytearray = new Uint8Array(2);
        bytearray[0] = CommandToServer.END_GAME_NOW
        bytearray[1] = (stat & 255)
        // 
        if (this.conn.readyState === WebSocket.OPEN) {
            this.conn.send(bytearray.buffer)
            console.log("sending end game now")
        }



        setTimeout(function()
         {
            self.conn.close();
         },9000);


        
        parent.postMessage({type: 'pp-var2', params: "endgame",status:stat }, "*");
                
        console.log("Closing game");
        console.log("Closing "+gamegl);


        
        //XXX gamegl.destroy(true,false);
        




    }

	openCloseDrawer(stat)
    {


        parent.postMessage({type: 'pp-var2', params: "drawer",status:stat }, "*");
        if (stat)
			this.ignoreClick=true;        
    }


	init(...params){
        console.info('INIT', JSON.stringify(params))
		console.log("[g] INGAME  session is  "+params[0].session+" hyatt is  "+params[0].hyatt_secret+" infra is  "+params[0].infra_secret+" character is "+params[0].character_type+" map is ["+params[0].map_type+"] address:"+params[0].ip+":"+params[0].port+" instance "+params[0].pid);

		let { width, height } = this.sys.game.canvas;
		isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		joystickSide=((params[0].key_on & 4)!=0);


		this.canvasWidth=this.sys.game.canvas.width;
		this.canvasHeight=this.sys.game.canvas.height;

		this.joyPosYBottom=this.canvasHeight-OUTER_RADIUS;
		this.joyPosYTop=this.canvasHeight/3;

		if (!joystickSide)
		{
			this.joyPosXLeft=OUTER_RADIUS;
			this.joyPosXRight=this.canvasWidth*0.4;
			this.joyFireLeft=this.canvasWidth/2;
			this.joyFireRight=this.canvasWidth;
		}
		else
		{
			this.joyFireLeft=0;
			this.joyFireRight=this.canvasWidth/2;
			this.joyPosXRight=this.canvasWidth-OUTER_RADIUS;
			this.joyPosXLeft=this.canvasWidth*0.6;
		}

		console.log("[j] got "+this.canvasWidth+" "+this.canvasHeight+" for canvas right "+this.joyPosXRight+" top "+this.joyPosYTop+" "+joystickSide);


		this.myCharacterSkin=params[0].character_type;
		this.mapType=params[0].map_type;

		this.hyatt_secret=params[0].hyatt_secret;
		this.infra_secret=params[0].infra_secret;
		this.sessionId=params[0].session;
		this.customFlag= params[0].custom_flag

		var ip=params[0].ip ;

		if (ip.startsWith("https://"))
			ip=ip.substring(8);

		this.portIp=ip+":"+params[0].port;
		this.sfxVolume=params[0].sfxvolume/10;
		this.musicVolume=params[0].musicvolume/10;

		this.key_on=params[0].key_on & 3;
		const kd=params[0].key_defs;
		if (kd!=null)
		{
			this.key_defs=[];
			const ka=kd.split(",");
			for(var i=0;i<ka.length;i++)
			{
				this.key_defs.push(parseInt(ka[i]));
			}


		}

		console.log("[g] volume is "+this.sfxVolume+" "+this.musicVolume+" "+this.key_defs+" "+this.key_on);
    }


	create() {


		this.scene.run('game-ui')


		WebFont.load({custom: { families: [ 'RFUNI' ]}});
		WebFont.load({custom: { families: [ 'SMALLRFUNI' ]}});

		this.loadFont('silkscreen','fonts/Silkscreen-Regular.ttf')
		this.loadFont('rfuni','rfuni.ttf')

  


		try {
			this.connSetup()
		} catch (error) {
			console.log(error)
		}
		console.log("Server connection made "+this.portIp)
		
		// NEW STUFF



		createCharacterAnims(this.anims)
		createCharacterPrezzaAnims(this.anims)
		createCharacterCarabinieriAnims(this.anims)
		createCharacterPdogeAnims(this.anims)
		createCharacterHedgefundAnims(this.anims)
		createCharacterWizardAnims(this.anims)
		createCharacterAshiAnims(this.anims)
		createCharacterBullAnims(this.anims)
		createCharacterBearAnims(this.anims)
		createCharacterBdsmAnims(this.anims)
		createCharacterApeAnims(this.anims)
		createCharacterNinjaAnims(this.anims)
		createCharacterKaratekaAnims(this.anims)

		createCharacterAmazonianAnims(this.anims)
		createCharacterArea51Anims(this.anims)
		createCharacterBananaAnims(this.anims)
		createCharacterDragonAnims(this.anims)
		createCharacterEagleAnims(this.anims)
		createCharacterKnightAnims(this.anims)
		createCharacterSpacemanAnims(this.anims)
		createCharacterTigerAnims(this.anims)
	

		createLizardAnims(this.anims)
		createChestAnims(this.anims)


		this.camera=this.cameras.main;
	

		this.input.keyboard?.addCapture('A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,+,-,*,/,#,],[,\\,\'')
		this.input.keyboard?.addCapture([9,48,49,50,51,52,53,54,55,56,57,111,223,106,192,191,222,16,91,18,17,220,134,34,35,36,37,33,45,46])

		this.input.keyboard.on('keydown', event =>
		{
			console.log("**** key"+event.keyCode+" pressed");
	
        //  if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ONE)
        //     {
		// 		if (isMobile)
		// 			this.openCloseDrawer(0);
		// 		else
		// 			this.sendEnd(0);
        //     }
		// 	else             if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.TWO)
        //     {
		// 		if (isMobile)
		// 			this.openCloseDrawer(1);
		// 		else
		// 		{
		// 			this.playSfx(this.deathSound);

		// 			this.sendEnd(1);
		// 		}
        //     }
		// 	else             if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.THREE)
        //     {
		// 		this.sendEnd(2);
        //     }
		// 	else if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.C)
		// 	{
	
		// 		this.faune.changeCharacter();
		// 	}
		// 	else 
			
			if (((this.key_on & 1) == 1) && (event.keyCode === this.key_defs[Game.KEY1_LEAVE]))
			{
				// if leave timer is not set then set the leave timer
				if (this.is_in_intro === false) this.setLeaveTimer()
			}
			else if (((this.key_on & 2) == 2) && (event.keyCode === this.key_defs[Game.KEY2_LEAVE]))
			{
				// if leave timer is not set then set the leave timer
				if (this.is_in_intro === false) this.setLeaveTimer()
			}


	
		});
	

		// var map
		var tileset

		// XXX override
		// this.mapType = "lon"
		console.log("[g] using map type "+this.mapType);

		if (this.mapType == "nyc") {
			map = this.make.tilemap({ key: 'city-nyc' })
			// const tileset = map.addTilesetImage('dungeon', 'tiles', 16, 16, 1, 2)
			tileset = map.addTilesetImage('city-nyc', 'tiles-nyc', 16, 16, 0, 0)
			this.music = this.sound.add('theme-nyc')
			this.anims.create({ key: 'teleglow', frames: this.anims.generateFrameNames('teleportsnyc', { prefix: 'tele-nyc-glowanimation-', end: 17, zeroPad: 2 }), repeat: -1 });

			}


		if (this.mapType == "tok") {
			map = this.make.tilemap({ key: 'city-tokyo' })
			// const tileset = map.addTilesetImage('dungeon', 'tiles', 16, 16, 1, 2)
			tileset = map.addTilesetImage('city-tokyo', 'tiles-tokyo', 16, 16, 0, 0)
			this.music = this.sound.add('theme-tok')
			this.anims.create({ key: 'teleglow', frames: this.anims.generateFrameNames('teleportstok', { prefix: 'portal-glow', end: 60, zeroPad: 2 }), repeat: -1 });

		}

		if (this.mapType == "kal") {
			map = this.make.tilemap({ key: 'city-kalevala' })
			// const tileset = map.addTilesetImage('dungeon', 'tiles', 16, 16, 1, 2)
			tileset = map.addTilesetImage('city-kalevala', 'tiles-kalevala', 16, 16, 0, 0)
			this.music = this.sound.add('theme-kal')
			this.anims.create({ key: 'teleglow', frames: this.anims.generateFrameNames('teleportskal', { prefix: 'salmon-jump-', end: 10, zeroPad: 2 }), repeat: -1 });

		}
		

		if (this.mapType == "zag") {
			map = this.make.tilemap({ key: 'city-zagreb' })
			// const tileset = map.addTilesetImage('dungeon', 'tiles', 16, 16, 1, 2)
			tileset = map.addTilesetImage('city-zagreb', 'tiles-zagreb', 16, 16, 0, 0)
			this.music = this.sound.add('theme-zag')
			this.anims.create({ key: 'teleglow', frames: this.anims.generateFrameNames('teleportszag', { prefix: 'tele-zgb-glowanimation-', end: 17, zeroPad: 2 }), repeat: -1 });

		}


		if (this.mapType == "lon") {
			map = this.make.tilemap({ key: 'city-london' })
			// const tileset = map.addTilesetImage('dungeon', 'tiles', 16, 16, 1, 2)
			tileset = map.addTilesetImage('city-london', 'tiles-london', 16, 16, 0, 0)
			this.music = this.sound.add('theme-lon')
			this.anims.create({ key: 'teleglow', frames: this.anims.generateFrameNames('teleportslon', { prefix: 'tele-ldn-glowanimation-', end: 17, zeroPad: 2 }), repeat: -1 });

		}


		// map.createStaticLayer('Ground', tileset)
		const groundLayer = map.createLayer('underlayer', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		groundLayer.setCollisionByProperty({ collides: true })

		const roadLayer = map.createLayer('Road', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		roadLayer.setCollisionByProperty({ collides: true })

		const edgeLayer = map.createLayer('edge', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer

		
		this.knives = this.physics.add.group({
			classType: Phaser.Physics.Arcade.Image,
			maxSize: 4 // XXX check this
		})

		this.eneknives = this.physics.add.group({
			classType: Phaser.Physics.Arcade.Image,
			maxSize: 116 // XXX check this
		})


				// create all bullets / knives
				for (let oi = 0; oi < 4; oi++) {
					let xstr = "knife" 
					const kamo  = this.knives.get(oi, oi, xstr)
					}
					// make them all invisible and inactive
					//let ox = 0
					this.knives.getChildren().forEach(element => {
						element.setActive(false)
						element.setVisible(false)
					//	let xstr = "knife" + ox.toString()
					//	element.setName(xstr)
					//	ox++
					});
			
				// create all bullets / knives
				for (let oi = 0; oi < 116; oi++) {
					let xstr = "knife" 
					const kamo  = this.eneknives.get(oi, oi, xstr)
					}
					// make them all invisible and inactive
					//let ox = 0
					this.eneknives.getChildren().forEach(element => {
						element.setActive(false)
						element.setVisible(false)
					//	let xstr = "knife" + ox.toString()
					//	element.setName(xstr)
					//	ox++
					});

		// getting potential appear points
		const appearLayer = map.getObjectLayer('appear')
		console.log("X**** There are "+appearLayer.objects.length+" appear points in this map")
		appearLayer.objects.forEach(appearObj => {
			console.log("X**** Appear point is x:"+appearObj.x+" y:"+appearObj.y)
		})
		
		// setting random appear point
		const ridx = Math.floor(Math.random() * appearLayer.objects.length);
		console.log("X**** choosing index :"+ridx)

		const appearx = appearLayer.objects[ridx].x
		const appeary = appearLayer.objects[ridx].y 
		console.log("X**** THE Appear point is x:"+appearx+" y:"+appeary)

		// this.faune = this.add.faune(128, 128, 'faune') 
		// create the main character
		this.faune = this.add.faune(appearx, appeary, 'faune') 

	

		MyPlayer.last_x = appearx
		MyPlayer.last_y = appeary
		this.sendXY()
	
		
		// this.faune = this.add.faune(34, 69, 'faune') 
		// MyPlayer.last_x = 34
		// MyPlayer.last_y = 69
		// this.sendXY()



		this.faune.changeCharacterTo(this.myCharacterSkin)
		this.faune.setKnives(this.knives)
		this.faune.initAnim(this.myCharacterSkin)
		//
		// this.faune.setInteractive(this.input.makePixelPerfect());



		
		this.lizards = this.physics.add.group({
			classType: Lizard,
			createCallback: (go) => {
				const lizGo = go as Lizard
				lizGo.body.onCollide = true
			}
		})

		this.oplayers = this.physics.add.group({
			classType: Oplayer,
			createCallback: (go) => {
				const opGo = go as Oplayer
				opGo.body.onCollide = true
			}
		})

				// create 4 enemies
				for (let oi = 0; oi < Game.MAX_PLAYERS-1; oi++) {
					const gamo: Oplayer = this.oplayers.get(oi, oi, "enemy")
					gamo.setInvisible()
					}
					// make them all invisible and inactive
					let oy = 0
					this.oplayers.getChildren().forEach(element  => {
						// let ystr = "knife" + oy.toString()
						// element.setName(ystr)
						element.setActive(false)
						
						// element.setVisible(false)
						// oy++
					});
			






		// this.oplayers.setDepth(this.faune.depth)

		const cKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.C);

		//groundLayer.setCollisionByProperty({ collides: true })

		// const wallsLayer = map.createStaticLayer('Walls', tileset)

		edgeLayer.setCollisionByProperty({ collides: true })

		const boundryLayer = map.createLayer('boundary', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		
		// if ((this.mapType == "zag") && (this.mapType == "kal")) {
		boundryLayer.setCollisionByProperty({ collides: true })
		// }

		const wallsLayer = map.createLayer('Objects', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		wallsLayer.setCollisionByProperty({ collides: true })

		const landmarkLayer = map.createLayer('landmarks', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer

		const overLayer = map.createLayer('overlayer', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		overLayer.setCollisionByProperty({ collides: true })



		// if (this.mapType == "zag") {
		// // XXX new in Zagreb
		landmarkLayer.setCollisionByProperty({ collides: true })
		// }


		this.chests = this.physics.add.staticGroup({
			classType: Chest
		})

		//const chestsLayer = map.createLayer('teleports', (tileset as Phaser.Tilemaps.Tileset), 0, 0) as Phaser.Tilemaps.TilemapLayer
		const chestsLayer = map.getObjectLayer('teleports')

		
		chestsLayer.objects.forEach(chestObj => {
			var chx = this.chests.get(chestObj.x! + chestObj.width! * 0.5, chestObj.y! - chestObj.height! * 0.5) // , 'teleports')
			chx.body.setSize(16,16)
		})

		this.tracking=this.faune;
		this.cameras.main.startFollow(this.faune, true)
		
		// create the minimap
//		if (!this.isSpecial)
//		{
//		this.minimap = this.cameras.add(270, 10, 120, 60).setZoom(0.09).setName('mini');
		var mapYOffset,mapXOffset,mapHeight;

		if (isMobile)
		{
			mapHeight=this.canvasHeight/4;
			mapYOffset=(this.canvasHeight*0.05);
			mapXOffset=this.canvasWidth-(this.canvasWidth*0.37);
		}
		else
		{
			mapHeight=60;
			mapYOffset=(this.canvasHeight*0.025);
			mapXOffset=this.canvasWidth-(this.canvasWidth*0.29);
		}
		
		//this.minimap = this.cameras.add(mapXOffset, mapYOffset, this.canvasWidth*0.27, mapHeight).setZoom(0.09).setName('mini');
		//this.minimap.ignore(groundLayer);
		//this.minimap.ignore(roadLayer);
		//this.minimap.ignore(wallsLayer);
		////this.minimap.ignore(overLayer);
        // this.minimap.setBackgroundColor('rgba(40, 40, 40, 2.5)');
        // this.minimap.scrollX = 1600;
        // this.minimap.scrollY = 300;
		// this.minimap.alphaBottomRight = 0;
		// this.minimap.alphaTopRight = 1;
		// this.minimap.alphaTopLeft = 0;
		// this.minimap.alphaBottomLeft = 1;
		// this.minimap.roundPixels = true
		// this.minimap.lerp = 1

		
		this.drawgraph = this.add.graphics();

		
		this.shSound = this.sound.add('shotsnd')
		this.cntSnd = this.sound.add('csnd')
		this.deathSound = this.sound.add('deathsnd')
		this.hitSound = this.sound.add('hitsnd1')


		this.soundGetReady = this.sound.add('sound-getready')
		this.soundSet = this.sound.add('sound-set')
		this.soundGo = this.sound.add('sound-go')

		this.music.loop = true
		// XXX music.play();
		this.music.play()
		console.log("Setting music volume to "+this.musicVolume);
		this.music.setVolume(this.musicVolume);

		// this.lizards = this.physics.add.group({
		// 	classType: Lizard,
		// 	createCallback: (go) => {
		// 		const lizGo = go as Lizard
		// 		lizGo.body.onCollide = true
		// 	}
		// })

		// this.oplayers = this.physics.add.group({
		// 	classType: Oplayer,
		// 	createCallback: (go) => {
		// 		const opGo = go as Oplayer
		// 		opGo.body.onCollide = true
		// 	}
		// })

//		const lizardsLayer = map.getObjectLayer('Lizards')
//		lizardsLayer.objects.forEach(lizObj => {
///			this.lizards.get(lizObj.x! + lizObj.width! * 0.5, lizObj.y! - lizObj.height! * 0.5, 'lizard')
//		})



if (Game.HIT_OPTION == 1) {
		this.physics.add.collider(this.faune, wallsLayer)
		// this.physics.add.collider(this.lizards, wallsLayer)

		this.physics.add.collider(this.faune, overLayer)
		this.physics.add.collider(this.faune, landmarkLayer)
		this.physics.add.collider(this.faune, edgeLayer)
		this.physics.add.collider(this.faune, boundryLayer)
		this.physics.add.collider(this.faune, roadLayer)
}
else if (Game.HIT_OPTION == 2) {
	this.physics.add.collider(this.faune, wallsLayer, this.handlePlayerInFlightCollision, undefined, this)
	// this.physics.add.collider(this.lizards, wallsLayer)

	this.physics.add.collider(this.faune, overLayer, this.handlePlayerInFlightCollision, undefined, this)
	this.physics.add.collider(this.faune, landmarkLayer, this.handlePlayerInFlightCollision, undefined, this)
	this.physics.add.collider(this.faune, edgeLayer, this.handlePlayerInFlightCollision, undefined, this)
	this.physics.add.collider(this.faune, boundryLayer, this.handlePlayerInFlightCollision, undefined, this)
	this.physics.add.collider(this.faune, roadLayer, this.handlePlayerInFlightCollision, undefined, this)
}

		// this.physics.add.collider(this.lizards, wallsLayer)

		// XXX
		// this.physics.add.collider(this.faune, chests, this.handlePlayerChestCollision, undefined, this)

		
		this.physics.add.collider(this.knives, wallsLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.knives, overLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.knives, landmarkLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.knives, edgeLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.knives, boundryLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
	
		this.physics.add.collider(this.eneknives, wallsLayer, this.handleEneKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.eneknives, overLayer, this.handleEneKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.eneknives, landmarkLayer, this.handleEneKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.eneknives, edgeLayer, this.handleEneKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		this.physics.add.collider(this.eneknives, boundryLayer, this.handleEneKnifeWallCollision, this.handleActiveCollisionOneCheck, this);
		
		
		//this.physics.add.overlap(this.knives, edgeLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this)
		//this.physics.add.overlap(this.knives, wallsLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this)
		//this.physics.add.overlap(this.knives, overLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this)
		//this.physics.add.overlap(this.knives, boundryLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this)
		//this.physics.add.overlap(this.knives, wallsLayer, this.handleKnifeWallCollision, this.handleActiveCollisionOneCheck, this)
		
		
		
		
		
		// this.physics.add.collider(this.knives, this.lizards, this.handleKnifeLizardCollision, undefined, this)



		this.physics.add.collider(this.knives, this.oplayers, this.handleKnifeOplayerCollision, this.handleActiveCollisionCheck, this)

		// XXXXXXXXXXXXXXXXXXXXX
		// XXX THIS WAS IN!
		this.physics.add.collider(this.eneknives, this.faune, this.handleKnifeManCollision, this.handleActiveCollisionCheck, this)
		this.physics.add.collider(this.chests, this.faune, this.handleTeleportManCollision, this.handleActiveCollisionCheck, this)


		// this.playerLizardsCollider = this.physics.add.collider(this.lizards, this.faune, this.handlePlayerLizardCollision, undefined, this)

		// this.text1 = this.add.text(50, 100, 'HOT$HOT', { font: "Silkscreen-Regular", fontSize:  '50px', fill: "#ffffff" });
        // this.text1.setStroke('#00f', 32);
        // this.text1.setShadow(2, 2, "#333333", 2, true, true);

	}


	private handleActiveCollisionOneCheck(obj1: Phaser.GameObjects.GameObject, obj2: Oplayer) {
		if (obj1.active)  return(true)
		else return(false)
		
	}

	private handleActiveCollisionCheck(obj1: Phaser.GameObjects.GameObject, obj2: Oplayer) {
		if ((obj1.active) && (obj2.active)) return(true)
		else return(false)
		
	}

	private handleKnifeOplayerCollision(obj1: Phaser.GameObjects.GameObject, obj2: Oplayer) {
//		if (obj1.active) && (obj2.active) {



if ((obj2.in_enemy_intro_period === false) && (obj2.cannot_get_hit === false)) {


this.playhitSound()

this.postFxPlugin.add(obj2, {
	thickness: 1,
	outlineColor: 0xc41c00
});
// Cascade 2nd outline

// this.postFxPlugin.add(obj1, {
// 	thickness: 2,
// 	outlineColor: 0xc0c000
// });
//obj2.setTint(0xff0000)

//if (Game.HIT_OPTION == 1) {
this.time.delayedCall(70, this.cancelObjFx, [obj2], this)
//}
		
		console.log("OPLAYER HITT!!!!!!!!!")
		console.log("HITT The pid is"+ obj2.pid)
		console.log("HITT bullet"+ obj1.pid, obj1.x, obj1.y, obj1.width, obj1.height)
		console.log("HITT enemy"+ obj2.pid, obj2.x, obj2.y, obj2.width, obj2.height)

		// XXX check if this bullet is this players kill
		if (obj1.playbul == 1) {
			console.log("HITT with playerbullet")
			// removed and moved toi watchman
			//watch this.registerKill(obj2.pid)
			this.faune.shotcount--;
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)

			if (this.faune.shotcount < 0) {
				console.log("HITT minus playerbullet should not happen")
				this.faune.shotcount = 0;
			}
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)


		}
		else { 
			console.log("HITT foreign bullet");
		}


			this.knives.killAndHide(obj1)

		// we could end timer if before knife kill
	}
	else {
		if (obj1.playbul == 1) {
			console.log("HITT in intro time playerbullet")
			this.faune.shotcount--;
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)

			if (this.faune.shotcount < 0) {
				console.log("HITT minus playerbullet should not happen")
				this.faune.shotcount = 0;
			}
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)
			this.knives.killAndHide(obj1)
	}
}


		// XXX obj1.destroy()

		// XXX dollar swap
		//this.knives.toggleVisible(obj1)
//	}
	}

    private cancelObjFx(obj1: Phaser.GameObjects.GameObject) {
		this.postFxPlugin.remove(obj1)
		obj1.clearTint()
	}


	private handleTeleportManCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		if (this.firstTele == 0) {
		console.log("X**** TELEPORT HITT!!!!!!!!!")
		this.sendPreEnd(Game.STATE_TELEPORT_START)
		this.faune.setBPGEnd();
		this.time.addEvent({ delay: 1000, callback: this.onFauneTeleDis, callbackScope: this, repeat: 0})
		this.time.addEvent({ delay: 4000, callback: this.onFauneTeleDis2, callbackScope: this, repeat: 0})
		this.firstTele = 1
	}

	}

	
	private handlePlayerInFlightCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		this.cancelObjFx(this.faune) 
		this.faune.recoverFromFlight()
	}



	private handleKnifeManCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		console.log("MAN HITT!!!!!!!!!")
		// console.log("The pid is"+ obj2.pid)
		//this.registerKill(obj2.pid)
		if ((this.is_in_intro == false) && (this.no_shoot_end == false)) {
		// this.knives.killAndHide(obj1)
		// this.knives.toggleVisible(obj1)
		this.playhitSound()
		if (Game.HIT_OPTION == 2) {
		
		this.faune.testHitFlight(obj2.body?.velocity.x, obj2.body?.velocity.y)
		}

		// this.postFxPlugin.add(obj1, {
		// 	thickness: 1,
		// 	outlineColor: 0x100000
		// });
		this.postFxPlugin.add(obj1, {
			thickness: 1,
			outlineColor: 0xc41c00
		});
		// Cascade 2nd outline

		// this.postFxPlugin.add(obj1, {
		// 	thickness: 2,
		// 	outlineColor: 0xc0c000
		// });
		obj1.setTint(0xff0000)


		if (Game.HIT_OPTION == 1) {
		this.time.delayedCall(70, this.cancelObjFx, [this.faune], this)
		}

		// if (Game.HIT_OPTION == 2) {
		//    this.time.delayedCall(500, this.faune.recoverFromFlight, [this.faune], this)

		// }


		// XXX was obj2.destroy()
		obj2.setActive(false)
		this.eneknives.killAndHide(obj2)
	
		// XXX dollar swap
		//this.knives.toggleVisible(obj1)

//untried and not sure if thsi is the right place to suspend leave timer
if (this.leavetimeron === true) {
		console.log("XXXX Suspend leave timer")
		// this.leaveEvent.reset()
		this.leaveEvent.remove()
		sceneEvents.emit('player-leavetimer-changed', -1)
		sceneEvents.emit('player-leaveover', false)
		this.leavetimeron = false
		this.sendPreEnd(Game.STATE_STOP_LEAVING)
		this.faune.stopLeaveAnims()
		// XXX this made it work? this.leaveEvent = null

}	

		}
		// when in intro period
		else {
			obj2.setActive(false)
			this.eneknives.killAndHide(obj2)
	
		}


	}



	private handlePlayerChestCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		const chest = obj2 as ChData / Roaming / npm / node_modules / parcel - bundler / srest
		this.faune.setChest(chest)
	}

	private handleKnifeWallCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		console.log("BCX HITT wall", this.faune.shotcount)
		// console.log("X**** BCX HITT wall", obj2)
		if (obj1.playbul == 1) {
			this.faune.shotcount -= 1
			if (this.faune.shotcount < 0) this.faune.shotcount = 0
			console.log("HITT wall", this.faune.shotcount)
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)

		}
		this.knives.killAndHide(obj1)
		obj1.playbul = 0;
		obj1.setActive(false);
		obj1.setVisible(false);
		// obj1.destroy()
	}



	private handleEneKnifeWallCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		this.eneknives.killAndHide(obj1)
		obj1.playbul = 0;
		obj1.setActive(false);
		obj1.setVisible(false);
		console.log("X****",obj2)
		// obj1.destroy()
	}


	private handleKnifeLizardCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		
		this.knives.killAndHide(obj1)
		this.lizards.killAndHide(obj2)
		obj2.destroy()
		if (obj1.playbul == 1) {
			this.faune.shotcount -= 1
			sceneEvents.emit('player-bullets-changed', this.faune.shotcount)

			console.log("BC minus x")
		}
	}

	private handlePlayerLizardCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
		const lizard = obj2 as Lizard

		const dx = this.faune.x - lizard.x
		const dy = this.faune.y - lizard.y

		const dir = new Phaser.Math.Vector2(dx, dy).normalize().scale(200)

		this.faune.handleDamage(dir)

		sceneEvents.emit('player-health-changed', this.faune.health)

		if (this.faune.health <= 0) {
			this.playerLizardsCollider?.destroy()
		}
	}



	// cancelFlight() {
	// 	// this.cancelObjFx(this.faune) 
	// 	// this.faune.recoverFromFlight()
	// 	sceneEvents.emit('stop_player_flight')

	// }




	doKSize(x) {
		x.body?.setSize(6,6, true)
	}


	// this function is to update the position of other (maybe all) players
	updateWithNewBullet(bulletInfo) {
		console.log("update new bullet processing")
		// Updates players positions and creates newly joined players
		bulletInfo.forEach(bullet => {
			if (bullet.id == MyPlayer.ID) {
				console.log("Ingornig my bullet")
			}
			else {
				/// XXXX Here to fix
				console.log("GOT NEW BULLET " + bullet.id + " " + bullet.x + " " + bullet.y + " " + bullet.vx + " " + bullet.vy)

				console.log("OBULL1 ")

				if (!this.eneknives)
				{
					return
				}
		
				console.log("OBULL2 ")
				
				const knife = this.eneknives.get(bullet.x,bullet.y, 'knife') as Phaser.Physics.Arcade.Image
				console.log("faune depth ", this.faune.depth)
				if (!knife)
				{
					return
				}
		
				console.log("OBULL3 ")
				
		
				//const parts = this.knives.anims.currentAnim.key.split('-')
		
				// const direction = parts[2]
		
				const vec = new Phaser.Math.Vector2(0, 0)
		
				vec.x = bullet.vx
		
				vec.y = bullet.vy
				if (vec.x == 255) vec.x = -1
				if (vec.y == 255) vec.y = -1
				
				// vec.x = 1
				// vec.y = -1
		
		
				const angle = vec.angle()
		
				knife.setActive(true)
				knife.setVisible(true)
		
				knife.setRotation(angle)
				this.time.addEvent({ delay: 250, callback: this.doKSize, args:[knife], callbackScope: this, repeat: 0 })
				knife.body?.setSize(6,6, true)
		
				knife.x += vec.x * 1 //16
				knife.y += vec.y * 1 //16
		
				// 
				knife.setVelocity(vec.x * 300, vec.y * 300)
		
				
				// this.scene.ss.play();		
				

				// XXX this.faune.throwKnifeOther(bullet.x, bullet.y, bullet.vx, bullet.vy)
				//            const playerIndex = this.xplayers.findIndex(p => p.id == player.id);

				//          if (playerIndex == -1) {
				//console.log("new oplayer " + player.id)
				// this.players.push(new Oplayer(this, player.id, player.x, player.y))

				// const ar: GameObjects = this.oplayers.getMatching('name', toString(player.id))
				// const ji = ar.length
				// console.log("j = " + ji)
				// if (ji == 0) {


				// 	this.oplayers
				// 	const gamo = this.oplayers.get(player.x!, player.y!)
				// 	gamo.pid = player.id
				// 	gamo.setData('opkey', toString(player.id))
				// 	gamo.setName(toString(player.id))
				// 	gamo.setXY(player.x, player.y)
				// }
				// else {
				// 	ar[0].setPosition(player.x, player.y);
				// }
			

				// this.lizards.get(player.x!, player.y!, 'lizard')
				//this.players[playerIndex].img = this.add.image(player.x, player.y, 'lizard')
				//        } else {
				//			console.log("xold oplayer "+ player.id)
				// this.players[playerIndex].img.setPosition(player.x,player.y);
				//this.players[playerIndex].update(player);
				//      }
			}
		});
	}


	loadFont(name, url) {
		var newFont = new FontFace(name, `url(${url})`);
		newFont.load().then(function (loaded) {
			document.fonts.add(loaded);
		}).catch(function (error) {
			return error;
		});
	}
	

	enemyGoExitMode(enidx,exitmode) {
		console.log("X**** exit mode for "+enidx+" = " + exitmode)
		const ar: Oplayer[] = this.oplayers.getMatching('pid', enidx)
		const ji = ar.length
		console.log("X**** player inquestion Xj = " + enidx+ "array len" + ji)
		if (ji > 0) {	
		switch (exitmode) {
			case Game.STATE_START_LEAVING:
				console.log("X**** setbpgglow = " + enidx)
				ar[0].setBPGlow()
				break;
			case Game.STATE_STOP_LEAVING:
				ar[0].stopLeaveAnims()
				break;
			case Game.STATE_FINAL_LEAVE_ANIM_START:
				ar[0].cannot_get_hit = true
				ar[0].setBPGEndwithDis()
				break;
			case Game.STATE_LEAVE_END:
				ar[0].stopLeaveAnims()
				this.removeEnemyPlayer(enidx)
				break;
			case Game.STATE_TELEPORT_START:
				ar[0].cannot_get_hit = true
				ar[0].setBPGEnd()
				break;
			case Game.STATE_TELEPORT_FINAL_ANIM:
				ar[0].setInvisible()
				ar[0].stopLeaveAnims()
				break;
			case Game.STATE_TELEPORT_END:
				this.removeEnemyPlayer(enidx)
				break;
		}
	}
		
	}


	// function to remove the player icon
	removeEnemyPlayer(pid) {
		const ar: GameObjects = this.oplayers.getMatching('pid', pid)
		//const ar: GameObjects = this.oplayers.getMatching('name', player.id)
		const ji = ar.length
		console.log("X**** player to be deleted Xj = " + ji)
		if (ji > 0) {	
			ar[0].setActive(false)
			ar[0].setInvisible()
		}
	}


	removeMyPlayer() {
		this.displayEnd(1)
	}



	// new function
	enemyEntryCharInfo(cidx : number, cht: number) 	{


		console.log("update enemy skin and info - skin = " + cht + " cidx " + cidx + "my id "+ MyPlayer.ID)
		if (cidx != MyPlayer.ID) {
			// not this player
			console.log("enemy oplayer " + cidx)
			// find existing enemy - time so enemy exists
			const ar: GameObjects = this.oplayers.getMatching('pid', cidx)
			const ji = ar.length
				console.log("XXXJ = " + ji)
				if (ji != 0) {
					// set character type for enemy
					ar[0].setXCT(cht)
				}
			}
		}



onEnemyIntroEnd(enemy) {
	enemy.in_enemy_intro_period = false
//	this.postFxPlugin.remove(enemy)
}

	// this function is to update the position of other (maybe all) players
	updatePositions(playersInfo) {
		console.log("update xplayers")
		// Updates players positions and creates newly joined players
		playersInfo.forEach(player => {
			if (player.id === MyPlayer.ID) { // added 3 = XXX
				console.log("Constructed Ingornig self")
			}
			else {
				console.log("SRVPKT oplayer Constructed " + player.id + " " + player.x + " " + player.y + " "+ player.skin+ " state"+ player.state)
				//            const playerIndex = this.xplayers.findIndex(p => p.id == player.id);

				//          if (playerIndex == -1) {
				
				// this.players.push(new Oplayer(this, player.id, player.x, player.y))

				 const ar: Oplayer[] = this.oplayers.getMatching('pid', player.id)
				//const ar: GameObjects = this.oplayers.getMatching('name', player.id)

				const ji = ar.length
				console.log("Xj = " + ji)
				if (ji == 0) {
					console.log("SRV player.skin ", player.skin)
					if (player.skin < 200) {
					console.log("new oplayer " + player.id)
					//this.oplayers
					// const gamo: GameObject = this.oplayers.get(player.x!, player.y!)
					const gamo: Oplayer = this.oplayers.get(player.x!, player.y!, "enemy")
					gamo.cannot_get_hit = false
					gamo.pid = player.id
					gamo.in_enemy_intro_period = true
					this.time.delayedCall(Game.INITIAL_WAIT_TIME, this.onEnemyIntroEnd, [gamo], this)
					// this.postFxPlugin.add(gamo, {
					// 	thickness: 1,
					// 	outlineColor: 0x00e8e8
					// });
					console.log("SPECIAL Constructed oplayer " + gamo.pid)
					console.log("Xj new construction")
					//gamo.setData('opkey', toString(player.id))
					//gamo.setData('name', toString(player.id))
					//gamo.setName(toString(player.id))
					//gamo.name = toString(player.id)
					//gamo.setActive(true)

					//gamo.last_x = gamo.x
					//gamo.last_y = gamo.y
					console.log("SPECIAL Constructed oplayer " + gamo.character_type + " "+ gamo.character_set)
					
					// the good one
					console.log("oplayer skin new ", player.skin)					
					gamo.setXCT(player.skin)
					
					console.log("SPECIAL Constructed oplayer " + gamo.character_type + " "+ gamo.character_set)					
					console.log("name to X " + toString(player.id) ) 
				
					//	gamo.setXY(player.x, player.y)
					gamo.setX(player.x)
					gamo.setY(player.y)
					// gamo.setDepth(this.faune.depth)
					//gamo.updateAnim()
					gamo.setVisible(true)
					gamo.setActive(true)
					//
					gamo.initHud2()

					if (player.state == 1) {
						if (gamo.in_hit_flight == 0) {
							gamo.in_hit_flight = 1
							//gamo.setTint(0xff0000)
							gamo.angle += 10
						} 
						else {
							gamo.angle += 10
						}
					} 
					else {
						if (gamo.in_hit_flight == 1) {
							gamo.in_hit_flight = 0
							//gamo.clearTint()
							gamo.angle = 0
						} 

					}


					// gamo.last_x = gamo.x
					// gamo.last_y = gamo.y
					// gamo.setPosition(player.x, player.y);
				    // gamo.updateAnim()

					// console.log("name to " + gamo.name) 
					// gamo.setName(toString(player.id))
					// gamo.name = toString(player.id)

					// request enemy information
					this.requestEnemyInfo(player.id)



					}
				}


				else {
					console.log("SRVPKT ANIM  " + player.id + " last ("+ ar[0].last_x + "," + ar[0].last_y + ") " + "new ("+ ar[0].x + "," + ar[0].y + ") ") 
					// ar[0].initAnim(8)
					ar[0].last_x = ar[0].x
					ar[0].last_y = ar[0].y
					ar[0].setPosition(player.x, player.y);
				    ar[0].updateAnim()


					// this.graphics.fillRect(this.faune.x+10,this.faune.y+10,10,10)
					// this.drawgraph.clear();
					// this.drawgraph.fillStyle(0xffff00, 0.5);
		
					// this.drawgraph.fillRect(50,50, 200,200); //13


					console.log("name to " + ar[0].name) 
					ar[0].setName(toString(player.id))
					ar[0].name = toString(player.id)

					
					if (player.state === 1) {
						if (ar[0].in_hit_flight === 0) {
							console.log("UUU tint on")
							ar[0].in_hit_flight = 1
							ar[0].setTint(0xff0000)
							ar[0].angle += 10
						} 
						else {
							ar[0].angle += 10
						}
					} 
					else {
						if (ar[0].in_hit_flight === 1) {
							console.log("UUU tint off")
							ar[0].in_hit_flight = 0
							ar[0].clearTint()
							this.cancelObjFx(ar[0])
							ar[0].angle = 0
						} 

					}


				}
			

				// this.lizards.get(player.x!, player.y!, 'lizard')
				//this.players[playerIndex].img = this.add.image(player.x, player.y, 'lizard')
				//        } else {
				//			console.log("xold oplayer "+ player.id)
				// this.players[playerIndex].img.setPosition(player.x,player.y);
				//this.players[playerIndex].update(player);
				//      }
			}
		});


		// Destroy left players
		// this.players.forEach(player => {
		//     const playerIndex = playersInfo.findIndex(p => p.id == player.id);
		//     if (playerIndex == -1) {
		//         let deleteIndex = this.players.findIndex(p => p.id == player.id);
		//         this.players[deleteIndex].destroy();
		//         this.players[deleteIndex] = null;
		//         this.players.splice(deleteIndex, 1);
		//     }
		// });

	}


	playGetReady() {
		this.playSfx(this.soundGetReady);
	}

	playSet() {
		this.playSfx(this.soundSet);
	}

	playGo() {
		this.playSfx(this.soundGo);
	}

	

	shootSound() {

		// this.cntSnd.play();
		this.playSfx(this.shSound);
		

	}

	playhitSound() {
		this.playSfx(this.hitSound)
	}


	playSfx(sound)
	{
		console.log("[g] Playing sound at "+this.sfxVolume);
		sound.setVolume(this.sfxVolume);
		sound.play();
	}


	cntSoundPlay() {


		this.playSfx(this.cntSnd);
		
		//this.shSound.play();
	}

	hexStringToByteArray(hexString) {
		if (hexString.length % 2 !== 0) {
			throw "Must have an even number of hex digits to convert to bytes";
		}/* w w w.  jav  a2 s .  c o  m*/
		var numBytes = hexString.length / 2;
		var byteArray = new Uint8Array(numBytes);
		for (var i=0; i<numBytes; i++) {
			byteArray[i] = parseInt(hexString.substr(i*2, 2), 16);
		}
		return byteArray;
	}


	requestToJoin() {

		console.log("Xending game join request")
		// array is 65@ 20 for hyatsecret  20 for infra secret 4 for character type 1 for command
		let bytearray = new Uint8Array(63);
/* 
		let session = "7777777777777777777777777777777777777777"
		let hyatt = "0123456789012345678901234567890123456789"
		let infra = "facac001facac001facac001facac001facac001"
*/		
		let session = this.sessionId;
		let hyatt = this.hyatt_secret
		let infra = this.infra_secret
		console.log("[g] Sending game join request "+session+" "+hyatt+" "+infra);
//		let charactedType = Math.floor(Math.random() * 10) + 1;
		let characterType = this.myCharacterSkin
		bytearray[0] = CommandToServer.REQUEST_TO_JOIN_GAME
		for (var i=0; i<20; i++) {
			bytearray[1+i] = parseInt(session.substr(i*2, 2), 16);
		}
		for (var i=0; i<20; i++) {
			bytearray[21+i] = parseInt(hyatt.substr(i*2, 2), 16);
		}

		for (var i=0; i<20; i++) {
			bytearray[41+i] = parseInt(infra.substr(i*2, 2), 16);
		}
		bytearray[61] = characterType >> 8 & 255
		bytearray[62] = characterType & 255
		if (this.conn.readyState === WebSocket.OPEN) {
			this.conn.send(bytearray.buffer)
			console.log("sending game join request")
		}

	}



	// Send information to server about player control inputs (movement, firing gun)
	registerKill(enemyID: number) {
		let bytearray = new Uint8Array(5);
		bytearray[0] = CommandToServer.UPDATE_REGISTER_KILL
		bytearray[1] = enemyID >> 24 & 255
		bytearray[2] = enemyID >> 16 & 255
		bytearray[3] = enemyID >> 8 & 255
		bytearray[4] = enemyID & 255
		// 
		if (this.conn.readyState === WebSocket.OPEN) {
			this.conn.send(bytearray.buffer)
			console.log("sending posxy")
		}
	}


requestEnemyInfo(enemyID: number) {
	let bytearray = new Uint8Array(5);
	bytearray[0] = CommandToServer.REQUEST_ENEMY_INFO
	bytearray[1] = enemyID >> 24 & 255
	bytearray[2] = enemyID >> 16 & 255
	bytearray[3] = enemyID >> 8 & 255
	bytearray[4] = enemyID & 255
	// 
	if (this.conn.readyState === WebSocket.OPEN) {
		this.conn.send(bytearray.buffer)
		console.log("sending request for enemy info")
	}

}



	


	// Send information to server about player control inputs (movement, firing gun)
	sendXY() {
		let bytearray = new Uint8Array(6);
		console.log("sending posxy SRVPKT " + MyPlayer.last_x + " " + MyPlayer.last_y)
		bytearray[0] = CommandToServer.UPDATE_PLAYER_XYPOS
		bytearray[1] = MyPlayer.last_x >> 8
		bytearray[2] = MyPlayer.last_x & 255
		bytearray[3] = MyPlayer.last_y >> 8
		bytearray[4] = MyPlayer.last_y & 255
		bytearray[5] = (this.faune.in_hit_flight & 1)
		// 
		if (this.conn.readyState === WebSocket.OPEN) {
			this.conn.send(bytearray.buffer)
			console.log("sending posxy")
		}
	}


	sendNewBullet() {
		// let bytearrayx = new Uint8Array(9);
		// bytearrayx[0] = CommandToServer.UPDATE_NEW_BULLET
		// bytearrayx[1] = MyPlayer.last_x >> 8
		// bytearrayx[2] = MyPlayer.last_x & 255
		// bytearrayx[3] = MyPlayer.last_y >> 8
		// bytearrayx[4] = MyPlayer.last_y & 255
		// bytearrayx[5] = (MyPlayer.vx >> 8) & 255
		// bytearrayx[6] = MyPlayer.vx & 255
		// bytearrayx[7] = (MyPlayer.vy >> 8) & 255
		// bytearrayx[8] = MyPlayer.vy & 255

		let bytearray = new Uint8Array(7);
		bytearray[0] = CommandToServer.UPDATE_NEW_BULLET
		bytearray[1] = MyPlayer.last_x >> 8
		bytearray[2] = MyPlayer.last_x & 255
		bytearray[3] = MyPlayer.last_y >> 8
		bytearray[4] = MyPlayer.last_y & 255
		bytearray[5] = MyPlayer.vx & 255
		bytearray[6] = MyPlayer.vy & 255
		
		
		
		// 
		if (this.conn.readyState === WebSocket.OPEN) {
			this.conn.send(bytearray.buffer)
			console.log("sending bullet XXXXXX")
		}
	}

	LOGG(str:String)
	{
//		console.log(str);
	}

	setFire(state)
	{
/*		if (this.cursors2!=undefined)
		{
				this.cursors2.space=new Phaser.Input.Keyboard.Key(state)
				console.log("[j] set space to "+state);
		}
		else
		{
			console.log("[j] NO CURSOR2!");
		}*/
	}

	pInfo(pointer: Phaser.Input.Pointer)
	{
		if (pointer==undefined)
			return "undefined";

		return pointer.active+" x="+Math.floor(pointer.worldY)+" y="+Math.floor(pointer.position.y)+" "+[pointer.isDown];
	}

	update(t: number, dt: number) {

		

		if (isMobile)
		console.log("[j] p1 "+this.pInfo(this.input.activePointer)+" "+joyStick.force+" "+joyStick.noKey);
		
		
		
		sceneEvents.emit('draw-enemy-points', 0)

		
/*		if(!this.input.activePointer.isDown && this.isClicking == true) {
			if (this.input.activePointer.position.x>(width/2))
				this.isClicking = false;
		} else*/ 
		
		if (this.ignoreClick==true)
		{
			if (!this.input.activePointer.isDown)
				this.ignoreClick=false;
		}
		else
		{
			if ((this.firePointer!=null) && this.isClicking==true)
			{
				if (!this.firePointer.isDown)
				{
					this.isClicking=false;
					this.firePointer=null;
				}
			}
			else			if(this.input.activePointer.isDown && this.isClicking == false) {
				if ((this.input.activePointer.position.x>this.joyFireLeft) && (this.input.activePointer.position.x<this.joyFireRight))
				{
					this.isClicking = true;
					this.firePointer=this.input.activePointer;

				}
				else if (isMobile)
				{
					// click is on the left hand side so check if it was an interaction with the the joystick and if not move the stick
					if (joyStick.force==0) // && this.input.activePointer.worldY<this.canvasHeight)
					{
						const y=this.input.activePointer.y;
						const x=this.input.activePointer.x;
						if ((x>this.joyPosXLeft) && (x<this.joyPosXRight) && (y>this.joyPosYTop) && (y<this.joyPosYBottom))
						{
							if (x!=joyStick.x && (y!=joyStick.y))
							{
								console.log("[j] placing joystick at "+this.input.activePointer.x,y);
								joyStick.setPosition(this.input.activePointer.x,y);
							}
						}
					}
				}
			}
		}



		if ((isMobile) && (this.cursors2==undefined) && (joyStick!=undefined))
		{
			this.cursors2 = joyStick.createCursorKeys();
			console.log("[j] created joystick cursor "+this.cursors);
		}
/*		else
			console.log("[j] isMobile "+isMobile+" CU="+this.cursors2+" JS="+joyStick);*/

		if (this.faune) {
			if (typeof this.sprogTimer == 'undefined') {
				console.log("undefined timer ")
			} else {
			if (this.sprogTimer.paused == false) {
			this.faune.sprogress = this.sprogTimer.getProgress()
			sceneEvents.emit('show-reloader-progress', this.faune.sprogress)
			}
			}
			this.shoudi = this.faune.update(this.cursors,this.cursors2,this.isClicking)
			this.LOGG("XXXX "+t+" "+dt)

			if ((MyPlayer.last_x != this.faune.x) || (MyPlayer.last_y != this.faune.y)) {
				console.log("coordchange "+t+" "+dt)
				MyPlayer.last_x = this.faune.x
				MyPlayer.last_y = this.faune.y
				this.sendXY()
			}
			// console.log("Xcoord ="+this.faune.x + " " + this.faune.y)
			if (this.shoudi == 5)  {     // this.shootSound()
										this.shootSound()
										MyPlayer.vx = this.faune.lastvx
										MyPlayer.vy = this.faune.lastvy


										this.faune.sprogress = 0
										this.sprogTimer =  this.time.addEvent({
											delay: 210,                // ms
											//callback: this.spgcallback,
											//args: [],
											callbackScope: this,
											loop: false
										});
										
										// this.physics.overlap()
										sceneEvents.emit('player-bullets-changed', this.faune.shotcount)

										this.sendNewBullet()
										console.log("New Bullet sound and send")


			}
			
		}


		// XXXX scroll width and height need to be as per playground
		if (t - this.lastt > 100) {
		//this.minimap.scrollX = Phaser.Math.Clamp(this.faune.x - 80, 400, 5096); // here
		//this.minimap.scrollY = Phaser.Math.Clamp(this.faune.y - 60, 200, 5096); // here
		this.lastt = t
		}


	}
}


class MyPlayer {
	static ID = -1;
	last_x = -1;
	last_y = -1;
	x = -1;
	y = -1;
	vx = -1;
	vy = -1;
	Flag = 'gb';
	Alias
}

class XPlayer {
	ID = -1;
	last_x = -1;
	last_y = -1;
	x = -1;
	y = -1;
	img: Phaser.Physics.Arcade.Image

}

// Codes of commands that are sent to server
class CommandToServer {
	static UPDATE_PLAYER_INPUT = 100
	static UPDATE_PLAYER_XYPOS = 101
	static UPDATE_NEW_BULLET = 102
	static UPDATE_REGISTER_KILL = 103
	static REQUEST_TO_JOIN_GAME = 104
    static REQUEST_END_GAME = 105
    static END_GAME_NOW = 106	
	static REQUEST_ENEMY_INFO = 107
}

// Codes of commands that are received from server
class CommandFromServer {
	static INIT_PLAYER = 0
	static INIT_MAP = 1
	static PLAYERS_POSITIONS = 2
	static BULLETS_POSITIONS = 3
	static NEW_BULLET = 4
	static NEW_BALANCE = 5
	static NEW_BALANCE_FLOAT = 6
	static NEW_ENEMY_ENTRY = 7
	static REMOVE_ENEMY = 8
	static ENEMY_INFO = 9
	static EXIT_STATE_INFORM = 10
}

class ControlsInputs {
	static MOVE_RIGHT = 0b0000_0001
	static MOVE_LEFT = 0b0000_0010
	static MOVE_DOWN = 0b0000_0100 // y axis inverted in game
	static MOVE_UP = 0b0000_1000 // y axis inverted in game
	static FIRE_GUN = 0b0001_0000  //JMF how does this work
}

class Helper {
	static byteMessageToPlayersInfo(b) {
		let tempPlayersInfo = [];

		for (let i = 1; i < b.length; i += 9) {
			let p = {
				id: Util.bytesToInt32(0, b[i + 1], b[i + 2], b[i + 3]),
				x: Util.bytesToInt16(b[i + 4], b[i + 5]),
				y: Util.bytesToInt16(b[i + 6], b[i + 7]),
				skin: Util.bytesToInt16(0, b[i + 0]),
				state: Util.bytesToInt8(b[i + 8])
			};
			console.log("OPLAYEr XXX id = "+p.id+" "+p.x+" "+p.y+" "+p.skin+" s"+p.state) 
			tempPlayersInfo.push(p);
		}

		return tempPlayersInfo;
	}


	static byteMessageToNewBulletInfo(b) {
		let tempBulletInfo = [];

		let i = 1;

			let p = {
				id: Util.bytesToInt32(b[i + 0], b[i + 1], b[i + 2], b[i + 3]),
				x: Util.bytesToInt16(b[i + 4], b[i + 5]),
				y: Util.bytesToInt16(b[i + 6], b[i + 7]),
				vx: Util.bytesToInt8(b[i+8]),
				vy: Util.bytesToInt8(b[i+9])
			};

			tempBulletInfo.push(p);

		return tempBulletInfo;
	}


	

	static byteMessageToBulletsPositions(b) {
		let positions = [];

		for (let i = 1; i < b.length; i += 4) {
			let bullet = {
				x: Util.bytesToInt16(b[i + 0], b[i + 1]),
				y: Util.bytesToInt16(b[i + 2], b[i + 3]),
			};
			positions.push(bullet);
		}

		return positions;
	}
}

class Util {
	static bytesToInt32(b1, b2, b3, b4) {
		return b1 << 24 | b2 << 16 | b3 << 8 | b4
	}

	static bytesToFloat64(b1, b2, b3, b4) {
		return b1 << 24 | b2 << 16 | b3 << 8 | b4
	}

	static bytesToInt16(b1, b2) {
		return b1 << 8 | b2
	}

	static bytesToInt8(b1) {
		return b1
	}
}