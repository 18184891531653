import Phaser from 'phaser'

enum Direction
{
	UP,
	DOWN,
	LEFT,
	RIGHT,
	IDLE
}

const randomDirection = (exclude: Direction) => {
	let newDirection = Phaser.Math.Between(0, 3)
	while (newDirection === exclude)
	{
		newDirection = Phaser.Math.Between(0, 3)
	}

	return newDirection
}


declare global
{
	namespace Phaser.GameObjects
	{
		interface GameObjectFactory
		{
			oplayer(x: number, y: number, texture: string, frame?: string | number): Oplayer
		}
	}
}




export default class Oplayer extends Phaser.Physics.Arcade.Sprite
{
	private direction = Direction.RIGHT
	private moveEvent: Phaser.Time.TimerEvent
	private knives?: Phaser.Physics.Arcade.Group
    pid: number = -2

	player_idle_down = 'carabinieri-idle-down'
	player_run_down = 'carabinieri-run-down'
	player_run_up = 'carabinieri-run-up'
	player_run_side = 'carabinieri-run-side'

	character_type = 1
	character_set = 0
	country = "al"
	alias = "al"

	cannot_get_hit: boolean = false

	firstrun: number = 0

	balVal: Text
	hudFlag: Phaser.GameObjects.Image
	nameText: Phaser.GameObjects.BitmapText
	bpg: Phaser.GameObjects.Sprite
	bpgend: Phaser.GameObjects.Sprite

	timr1
	blinkcnt
	in_hit_flight = 0





	head_up_option: number = 2
	in_enemy_intro_period: boolean = true


	last_dir = -1

	last_x = 0
	last_y = 0


	constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number)
	{

		

		super(scene, x, y, texture, frame)



		this.cannot_get_hit = false
		this.pid = -1
		console.log("OPLAYER Constructed enemy with pid "+ this.pid)
		
		this.hudFlag = new Phaser.GameObjects.Image(this.scene, this.x, this.y - 16,'flagsheet','se.png');
		this.hudFlag.setOrigin(0, 0)
		this.hudFlag.setScale(1,1)
		//this.hudFlag.setSize(12,8)
		//this.hudFlag.setDisplaySize(12,8)

		scene.add.existing(this.hudFlag);

		this.nameText =  new Phaser.GameObjects.BitmapText(this.scene, this.x, this.y - 4,'SMALLRFUNI',"MAYOR_OF_LONDON");
		this.nameText.setOrigin(0, 0)
		this.nameText.setScale(1,1)
		scene.add.existing(this.nameText);



		this.scene.anims.create({
    		key: 'bailglowanim',
			frames: this.scene.anims.generateFrameNames('bpglow', { start: 0, end: 59, prefix: 'BailOut-PortalGlow_', suffix: '.png', zeroPad: 5 }),
			repeat: -1,
			frameRate: 40
		});


		this.scene.anims.create({
    		key: 'bailglowendanim',
			frames: this.scene.anims.generateFrameNames('bpgend', { start: 0, end: 46, prefix: 'bailoutportalglowend_', suffix: '.png', zeroPad: 5 }),
			repeat: 0,
			frameRate: 30
		});




		this.bpg = scene.add.sprite(this.x, this.y + 16,'bpglow')	
		this.bpg.setVisible(false)



		this.bpgend = scene.add.sprite(this.x, this.y + 16,'bpglow')	
		this.bpgend.setVisible(false)


        // animate character
		this.anims.play(this.player_idle_down)


		this.initHud()

		// new
		//this.body?.setSize(this.width * 0.5, this.height * 0.8)
		this.scene.time.addEvent({ delay: 250, callback: this.doSize, callbackScope: this, repeat: 0 })
		//this.body?.setSize(16,16,true)
		//this.setInteractive(new Phaser.Geom.Rectangle(0, 0, 16, 16))
		// this.input?.hitArea = new Phaser.Geom.Rectangle(0, 0, 16, 16)

		//this.scene.input.setHitArea(this,new Phaser.Geom.Rectangle(0, 0, 16, 16))
		

		scene.physics.world.on(Phaser.Physics.Arcade.Events.TILE_COLLIDE, this.handleTileCollision, this)

		this.moveEvent = scene.time.addEvent({
			delay: 2000,
			callback: () => {
				this.direction = randomDirection(this.direction)
			},
			loop: true
		})
	}


doSize() {
	// this.body?.setSize(16,16,true)
	this.body?.setSize(this.width * 0.5, this.height * 0.8, true)
	
}



setBPGlow()
{
this.bpg.play('bailglowanim')
this.bpg.setVisible(true)
}

setBPGEnd()
{
this.bpg.play('bailglowendanim');
this.bpgend.play('bailglowendanim');
this.bpgend.setVisible(true)
}



setBPGEndwithDis()
{
this.bpg.play('bailglowendanim');
this.bpgend.play('bailglowendanim');
this.bpgend.setVisible(true)
this.scene.time.addEvent({ delay: 250, callback: this.setInvisible, callbackScope: this, repeat: 0 })

}









setBPGOver()
{
this.bpg.play('bailglowendanim');
this.bpgend.play('bailglowendanim');
this.bpg.setVisible(false)
this.bpgend.setVisible(false)
}

stopLeaveAnims()
{
	this.bpg.setVisible(false)
	this.bpgend.setVisible(false)
	
}



setBlinkState() {

	const x = (this.blinkcnt % 4) 
	if (x == 0) {
		this.setTintFill(0x0000ff,0x0000ff,0xffffff,0xffffff);
		this.setAlpha(0.5)
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x002cff
		});
	}
	else if (x == 1) {
		this.setTintFill(0x0000ff,0x0000ff,0xffffff,0xffffff);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x000000
		});
	}
	else if (x == 2) {
		this.setTintFill(0x000000,0x000000,0x000000,0x000000);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0xff5000
		});
	}
	else {
		this.setTintFill(0x000000,0x000000,0x000000,0x000000);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x000000
		});
	}

	

	console.log("xxxxxxxxxxxxxxxxx timer")
	this.blinkcnt++;
}



goNormal() {
	this.timr1.remove()
	this.scene.postFxPlugin.remove(this)
	this.clearTint()
	this.setAlpha(1)
	
	}


	// testHitFlight(vx,vy) {
	// 	this.in_hit_flight = 1
	// 	this.setVelocity(vx*3 , vy*3)
	// }

	recoverFromFlight() {
	 	this.in_hit_flight = 0
	 	this.setVelocity(0 , 0)
	 	this.angle = 0.0
	}


initHud()
{

	this.blinkcnt = 0
	//this.setTintFill(0x004cff,0xbbbbff,0x004cff,0xbbbbff);
	this.timr1 = this.scene.time.addEvent({ delay: 200, callback: this.setBlinkState, callbackScope: this, repeat: 100 })
	this.scene.time.addEvent({ delay: 8000, callback: this.goNormal, callbackScope: this, repeat: 0 })
	this.setVisible(true)


	if (this.head_up_option === 2) {
		this.hudFlag.setVisible(true)
		this.nameText.setVisible(true)
		}
	else if (this.head_up_option === 1) {
		this.balVal.setVisible(true)	
	}
}


initHud2()
{

	this.setVisible(true)


	if (this.head_up_option === 2) {
		this.hudFlag.setVisible(true)
		this.nameText.setVisible(true)
		}
	else if (this.head_up_option === 1) {
		this.balVal.setVisible(true)	
	}
}




	initAnim(i: number) {
		if (i === 1) {		
			this.player_idle_down = 'carabinieri-idle-down'
			this.player_run_down = 'carabinieri-run-down'
			this.player_run_up = 'carabinieri-run-up'
			this.player_run_side = 'carabinieri-run-side'			
		} else if (i === 2) {
			this.player_idle_down = 'prezza-idle-down'
			this.player_run_down = 'prezza-run-down'
			this.player_run_up = 'prezza-run-up'
			this.player_run_side = 'prezza-run-side'			
		} else if (i === 3) {
			this.player_idle_down = 'ashi-idle-down'
			this.player_run_down = 'ashi-run-down'
			this.player_run_up = 'ashi-run-up'
			this.player_run_side = 'ashi-run-side'			
		} else if (i === 4) {
			this.player_idle_down = 'bull-idle-down'
			this.player_run_down = 'bull-run-down'
			this.player_run_up = 'bull-run-up'
			this.player_run_side = 'bull-run-side'			
		} else if (i === 5) {
			this.player_idle_down = 'wizard-idle-down'
			this.player_run_down = 'wizard-run-down'
			this.player_run_up = 'wizard-run-up'
			this.player_run_side = 'wizard-run-side'			
		} else if (i === 6) {
			this.player_idle_down = 'ape-idle-down'
			this.player_run_down = 'ape-run-down'
			this.player_run_up = 'ape-run-up'
			this.player_run_side = 'ape-run-side'			
		} else if (i === 7) {
			this.player_idle_down = 'bear-idle-down'
			this.player_run_down = 'bear-run-down'
			this.player_run_up = 'bear-run-up'
			this.player_run_side = 'bear-run-side'			
		} else if (i === 8) {
			// console.log("SPECIAL HEDGEFUN")
			this.player_idle_down = 'hedgefund-idle-down'
			this.player_run_down = 'hedgefund-run-down'
			this.player_run_up = 'hedgefund-run-up'
			this.player_run_side = 'hedgefund-run-side'			
		} else if (i === 9) {
			this.player_idle_down = 'pdoge-idle-down'
			this.player_run_down = 'pdoge-run-down'
			this.player_run_up = 'pdoge-run-up'
			this.player_run_side = 'pdoge-run-side'			
		} else if (i === 10) {
			this.player_idle_down = 'bdsm-idle-down'
			this.player_run_down = 'bdsm-run-down'
			this.player_run_up = 'bdsm-run-up'
			this.player_run_side = 'bdsm-run-side'			
		} else if (i === 11) {
			this.player_idle_down = 'karateka-idle-down'
			this.player_run_down = 'karateka-run-down'
			this.player_run_up = 'karateka-run-up'
			this.player_run_side = 'karateka-run-side'			
		} else if (i === 12) {
			this.player_idle_down = 'ninja-idle-down'
			this.player_run_down = 'ninja-run-down'
			this.player_run_up = 'ninja-run-up'
			this.player_run_side = 'ninja-run-side'			
		} else if (i == 13) {
		this.player_idle_down = 'amazonian-idle-down'
		this.player_run_down = 'amazonian-run-down'
		this.player_run_up = 'amazonian-run-up'
		this.player_run_side = 'amazonian-run-side'		
	} else if (i == 14) {
		this.player_idle_down = 'area51-idle-down'
		this.player_run_down = 'area51-run-down'
		this.player_run_up = 'area51-run-up'
		this.player_run_side = 'area51-run-side'		
	} else if (i == 15) {
		this.player_idle_down = 'banana-idle-down'
		this.player_run_down = 'banana-run-down'
		this.player_run_up = 'banana-run-up'
		this.player_run_side = 'banana-run-side'		
	} else if (i == 16) {
		this.player_idle_down = 'dragon-idle-down'
		this.player_run_down = 'dragon-run-down'
		this.player_run_up = 'dragon-run-up'
		this.player_run_side = 'dragon-run-side'		
	} else if (i == 17) {
		this.player_idle_down = 'eagle-idle-down'
		this.player_run_down = 'eagle-run-down'
		this.player_run_up = 'eagle-run-up'
		this.player_run_side = 'eagle-run-side'		
	} else if (i == 18) {
		this.player_idle_down = 'knight-idle-down'
		this.player_run_down = 'knight-run-down'
		this.player_run_up = 'knight-run-up'
		this.player_run_side = 'knight-run-side'		
	} else if (i == 19) {
		this.player_idle_down = 'spaceman-idle-down'
		this.player_run_down = 'spaceman-run-down'
		this.player_run_up = 'spaceman-run-up'
		this.player_run_side = 'spaceman-run-side'		
	} else if (i == 20) {
		this.player_idle_down = 'tiger-idle-down'
		this.player_run_down = 'tiger-run-down'
		this.player_run_up = 'tiger-run-up'
		this.player_run_side = 'tiger-run-side'			
	}
	this.anims.play(this.player_idle_down)


	}


	setInvisible() {

		if (this.head_up_option == 2) {
		this.hudFlag.setVisible(false)
		this.nameText.setVisible(false)
		}
		else if (this.head_up_option == 1) {
		this.balVal.setVisible(false)	
		}
		
		this.setVisible(false)
		
		}
	
	setFlag(country: string) {
		this.hudFlag.setTexture('flagsheet',(country.toLowerCase()+'.png'))
	}

	setAlias(alias: string) {
		this.nameText.text = alias
	}


	setXCT(i: number) {
		if (this.character_type !== i) {
		console.log("OPLAYER SPECIALCHECK ANIM charact init"+ this.character_type + this.character_set + i)
	    
		this.initAnim(i)
		this.character_type = i
		this.character_set = 1
		}

	}




	updateAnim() {

	// this.setXCT(8)
	console.log("OPLAYER ANIM charact init"+ this.character_type + this.character_set)

	var dir = Direction.IDLE
	console.log("ANIM doing anim check for enemies"+ this.character_type + dir + this.last_dir)

	if (this.x < this.last_x) {
		dir = Direction.LEFT
	}
	if (this.x > this.last_x) {
		dir = Direction.RIGHT
	}
	if (this.y < this.last_y) {
		dir = Direction.UP
	}
	if (this.y > this.last_y) {
		dir = Direction.DOWN
	}
	
	if (dir != this.last_dir) {

		if (dir === Direction.RIGHT) {
		this.anims.play(this.player_run_side, true)
		this.scaleX = 1
		this.body.offset.x = 8

		} 
		else if (dir === Direction.LEFT) {
			this.anims.play(this.player_run_side, true)
			this.scaleX = -1
			this.body.offset.x = 24

		}
		else if (dir === Direction.UP) {
 			this.anims.play(this.player_run_up, true)
		}
		else if (dir === Direction.DOWN) {
			this.anims.play(this.player_run_down, true)
		}

		else if (dir === Direction.IDLE) {
			// this.anims.play(this.player_run_down, true)
			const parts = this.anims.currentAnim.key.split('-')
			parts[1] = 'idle'
			this.anims.play(parts.join('-'))
		}

		// this.setVelocity(-100, 0)
		console.log("ANIM 3 "+ dir + this.last_dir)

	}


	this.last_dir = dir

	Phaser.Display.Bounds.SetBottom(this.nameText, (this.body.top-4));
	Phaser.Display.Bounds.SetCenterX(this.nameText, (this.body.center.x));
	this.nameText.update()

	Phaser.Display.Bounds.SetBottom(this.hudFlag, (this.body.top-16));
	Phaser.Display.Bounds.SetCenterX(this.hudFlag, (this.body.center.x));
	this.hudFlag.update()

	if (this.bpg.visible) {
 	Phaser.Display.Bounds.SetBottom(this.bpg, (this.body.top+38));
 	Phaser.Display.Bounds.SetCenterX(this.bpg, (this.body.center.x));
	this.bpg.update()
	}


	if (this.bpgend.visible) {
	
	
	Phaser.Display.Bounds.SetBottom(this.bpgend, (this.body.top+38));
	Phaser.Display.Bounds.SetCenterX(this.bpgend, (this.body.center.x));
	this.bpgend.update()
	if (this.firstrun == 0) {
		this.scene.children.bringToTop(this.bpgend);
		this.firstrun = 1
	}
   
	}

	// // XXX This is how to bring things on top!

	 
// 	if ((this.x != this.last_x) || (this.y != this.last_x)) {

// 	if (this.x < this.last_x)
// 	{
	
// 		this.anims.play(this.player_run_side, true)

// 		//this.setVelocity(-speed, 0)

// 	//	this.scaleX = -1
// 	//	this.body.offset.x = 24
// 	}
// 	else if (this.x > this.last_x)
// 	{
// //			this.anims.play('faune-run-side', true)
// 		//this.anims.play('prezza-run-side', true)
// 		//this.anims.play('carabinieri-run-side',true)
// 		this.anims.play(this.player_run_side,true)

		
// 		//this.setVelocity(speed, 0)

// 	//	this.scaleX = 1
// 	//	this.body.offset.x = 8
// 	}
// 	else if (this.y < this.last_y)
// 	{
// 		// this.anims.play('faune-run-up', true)
// 		// this.anims.play('prezza-run-up', true)
// 		//this.anims.play('carabinieri-run-up', true)
// 		this.anims.play(this.player_run_up, true)

// 		//this.setVelocity(0, -speed)
// 	}
// 	else if (this.y > this.last_y)
// 	{
// //			this.anims.play('faune-run-down', true)
// 		//this.anims.play('prezza-run-down', true)
// 		//this.anims.play('carabinieri-run-down', true)
// 		this.anims.play(this.player_run_down, true)

// 		//this.setVelocity(0, speed)
// 	}
// 	else
// 	{
// 		const parts = this.anims.currentAnim.key.split('-')
// 		parts[1] = 'idle'
// 		this.anims.play(parts.join('-'))
// 	//	this.setVelocity(0, 0)
// 	}
// }
	


	}

	destroy(fromScene?: boolean)
	{
		this.moveEvent.destroy()

		super.destroy(fromScene)
	}

	private handleTileCollision(go: Phaser.GameObjects.GameObject, tile: Phaser.Tilemaps.Tile)
	{
		if (go !== this)
		{
			return
		}

		this.direction = randomDirection(this.direction)
	}

	preUpdate(t: number, dt: number)
	{
		super.preUpdate(t, dt)

		const speed = 50

		this.setVelocity(0,0)
		
		//this.updateAnim()

/*
		switch (this.direction)
		{
			case Direction.UP:
				this.setVelocity(0, -speed)
				break

			case Direction.DOWN:
				this.setVelocity(0, speed)
				break

			case Direction.LEFT:
				this.setVelocity(-speed, 0)
				break

			case Direction.RIGHT:
				this.setVelocity(speed, 0)
				break
		}
	}
	*/
}
}

// export { setCharacterType, character_type}
