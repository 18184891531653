import Phaser from 'phaser'
import Chest from '../items/Chest'
import { Text } from './Text'


import { sceneEvents } from '../events/EventsCenter'
import Game from '~/scenes/Game'
import { ThetaSapphireTestnet, _0xtade } from '@thirdweb-dev/chains'

declare global
{
	namespace Phaser.GameObjects
	{
		interface GameObjectFactory
		{
			faune(x: number, y: number, texture: string, frame?: string | number): Faune
		}
	}
}

enum HealthState
{
	IDLE,
	DAMAGE,
	DEAD
}

export default class Faune extends Phaser.Physics.Arcade.Sprite
{
	private healthState = HealthState.IDLE
	private damageTime = 0

	private _health = 3
	private _coins = 0
	private _character = 0

	private knives?: Phaser.Physics.Arcade.Group
	private activeChest?: Chest

	lastvx: number = 0
	lastvy: number = 0

	lastleftDown: boolean = false
	lastrightDown: boolean = false
	lastupDown: boolean =  false
	lastdownDown: boolean =  false

	newLeftDown: boolean = false
	newRightDown: boolean =false
	newUpDown: boolean =false
	newDownDown: boolean =false

	sprogress: number = 1

	blinkcnt



	lastbitdown: number = 0
	lastsumdown: number = 0
	//////checking this


	downhistory: number[] = [0,0,0,0,0,0]
	newhistory: number[] = [0,0,0,0,0,0]
	downidx: number = 0

	balVal: Text
	hudFlag: Phaser.GameObjects.Image
	nameText: Phaser.GameObjects.BitmapText
	bpg: Phaser.GameObjects.Sprite
	bpgend: Phaser.GameObjects.Sprite
	
	
	shotcount = 0
	timr1
	deathtimercnt = -1
	deathtimedevent
	flightCtr = 0

	gameplay_option: number = 2
	head_up_option: number = 2
	hit_option: number = 2

	in_hit_flight = 0


	xplayerid: number = 1

	player_idle_down = 'carabinieri-idle-down'
	player_run_down = 'carabinieri-run-down'
	player_run_up = 'carabinieri-run-up'
	player_run_side = 'carabinieri-run-side'


	firstrun: number = 0

	get health()
	{
		return this._health
	}

	constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number)
	{

		super(scene, x, y, texture, frame)

		sceneEvents.on('stop_player_flight', this.recoverFromFlight, this)

		

		if ( this.head_up_option == 1) {

		this.balVal = new Text(this.scene, this.x, this.y - 28, "")
		// .setFontSize(9);
//		.setOrigin(0.8, 0.5);

		this.balVal.setText("$$$$$$")
		}

		else if  ( this.head_up_option == 2) {

		
		this.hudFlag = new Phaser.GameObjects.Image(this.scene, this.x, this.y - 16,'flagsheet','se.png');
		this.hudFlag.setOrigin(0, 0)
		this.hudFlag.setScale(1,1)
		//this.hudFlag.setSize(12,8)
		//this.hudFlag.setDisplaySize(12,8)

		scene.add.existing(this.hudFlag);

		this.nameText =  new Phaser.GameObjects.BitmapText(this.scene, this.x, this.y - 4,'SMALLRFUNI',"MAYOR_OF_LONDON");
		this.nameText.setOrigin(0, 0)
		this.nameText.setScale(1,1)
		scene.add.existing(this.nameText);
		}

		// this.bpg = new Phaser.GameObjects.Image(this.scene, this.x, this.y + 16,'bpglow','BailOut-PortalGlow_00001.png.png');
		// this.bpg.setOrigin(0, 0)
		// this.bpg.setScale(1,1)
		// //this.hudFlag.setSize(12,8)
		// //this.hudFlag.setDisplaySize(12,8)

		// scene.add.existing(this.bpg);
		this.scene.anims.create({
    		key: 'bailglowanim',
			frames: this.scene.anims.generateFrameNames('bpglow', { start: 0, end: 59, prefix: 'BailOut-PortalGlow_', suffix: '.png', zeroPad: 5 }),
			repeat: -1,
			frameRate: 40
		});


		this.scene.anims.create({
    		key: 'bailglowendanim',
			frames: this.scene.anims.generateFrameNames('bpgend', { start: 0, end: 46, prefix: 'bailoutportalglowend_', suffix: '.png', zeroPad: 5 }),
			repeat: 0,
			frameRate: 30
		});



		this.scene.anims.create({
    		key: 'pjoinanim',
			frames: this.scene.anims.generateFrameNames('pjoin', { start: 0, end: 29, prefix: 'portaljoin_', suffix: '.png', zeroPad: 5 }),
			repeat: 0,
			frameRate: 40
		});


		this.bpg = scene.add.sprite(this.x, this.y + 16,'bpglow')	
		this.bpg.setVisible(false)





		this.bpgend = scene.add.sprite(this.x, this.y + 16,'bpglow')	
		this.bpgend.setVisible(false)



		// this.anims.play('faune-idle-down')
		//this.anims.play('prezza-idle-down')
		// this.anims.play('carabinieri-idle-down')
		this.anims.play(this.player_idle_down)
		//this.scene.children.bringToTop(this.bpg);
		this.setEnterGlow()



	}






onDisappearFaune() {



if (this.head_up_option == 2) {
this.hudFlag.setVisible(false)
this.nameText.setVisible(false)
}
else if (this.head_up_option == 1) {
this.balVal.setVisible(false)	
}

this.setVisible(false)

}


goNormal() {
this.timr1.remove()
this.scene.postFxPlugin.remove(this)
this.clearTint()
this.setAlpha(1)

}

onAppearFaune() {


if (this.head_up_option == 2) {
	this.hudFlag.setVisible(true)
	this.nameText.setVisible(true)
	}
else if (this.head_up_option == 1) {
	this.balVal.setVisible(true)	
}
	
//this.scene.postFxPlugin.remove(this)
//this.clearTint()
this.blinkcnt = 0
//this.setTintFill(0x004cff,0xbbbbff,0x004cff,0xbbbbff);
this.timr1 = this.scene.time.addEvent({ delay: 200, callback: this.setBlinkState, callbackScope: this, repeat: 100 })
this.scene.time.addEvent({ delay: 6000, callback: this.goNormal, callbackScope: this, repeat: 0 })
this.setVisible(true)

}



setBlinkState() {

	const x = (this.blinkcnt % 4) 
	if (x == 0) {
		this.setTintFill(0x0000ff,0x0000ff,0xffffff,0xffffff);
		this.setAlpha(0.5)
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x002cff
		});
	}
	else if (x == 1) {
		this.setTintFill(0x0000ff,0x0000ff,0xffffff,0xffffff);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x000000
		});
	}
	else if (x == 2) {
		this.setTintFill(0x000000,0x000000,0x000000,0x000000);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0xff5000
		});
	}
	else {
		this.setTintFill(0x000000,0x000000,0x000000,0x000000);
		this.scene.postFxPlugin.remove(this)
		this.scene.postFxPlugin.add(this, {
			thickness: 1,
			outlineColor: 0x000000
		});
	}

	

	console.log("xxxxxxxxxxxxxxxxx timer")
	this.blinkcnt++;
}








	endBPGGlow()
	{

		this.bpgend.setVisible(false)
	}

	setEnterGlow()
	{
		this.onDisappearFaune()
		this.bpgend.play('pjoinanim');
		this.bpgend.setVisible(true)
		this.bpgend.on('animationcomplete', this.endBPGGlow, this);
		this.scene.time.addEvent({ delay: 360, callback: this.onAppearFaune, callbackScope: this, repeat: 0})

	}

	setBPGlow()
	{
	this.bpg.play('bailglowanim')
	this.bpg.setVisible(true)
	}
	

	setBPGEnd()
	{
	this.bpg.play('bailglowendanim');
	this.bpgend.play('bailglowendanim');
	this.bpgend.setVisible(true)
	}

	setBPGOver()
	{
	this.bpg.play('bailglowendanim');
	this.bpgend.play('bailglowendanim');
	this.bpg.setVisible(false)
	this.bpgend.setVisible(false)
	}

	stopLeaveAnims()
	{
		this.bpg.setVisible(false)
		this.bpgend.setVisible(false)
	}

	setFauneDead()
	{
		this.healthState = HealthState.DEAD
	}

	setFauneAlive()
	{
		this.healthState = HealthState.IDLE
	}

	setOverhead(sometext)
	{
		if (this.head_up_option ===1) {
		this.balVal.setText(sometext)
		}
	}

	changeCharacter()
	{
		this._character = this._character + 1
		if (this._character > 20) {
			this._character = 0
		}
		this.initAnim(this._character)
	}



	setFlag(country: string) {
		this.hudFlag.setTexture('flagsheet',(country.toLowerCase()+'.png'))
	}

	setAlias(alias: string) {
		this.nameText.text = alias
	}


	changeCharacterTo(i)
	{
		this._character = i
		this.initAnim(i)
	}

	setKnives(knives: Phaser.Physics.Arcade.Group)
	{
		this.knives = knives
	}

	setChest(chest: Chest)
	{
		this.activeChest = chest
	}

	handleDamage(dir: Phaser.Math.Vector2)
	{
		if (this._health <= 0)
		{
			return
		}

		if (this.healthState === HealthState.DAMAGE)
		{
			return
		}

		--this._health

		if (this._health <= 0)
		{
			// TODO: die
			this.healthState = HealthState.DEAD
			// this.anims.play('faune-faint')
			this.anims.play('prezza-faint')

			this.setVelocity(0, 0)
		}
		else
		{
			this.setVelocity(dir.x, dir.y)

			this.setTint(0xff0000)

			this.healthState = HealthState.DAMAGE
			this.damageTime = 0
		}
	}



	throwKnifeOther(x,y,vx,vy)
	{

		console.log("OBULL1 ")

		if (!this.knives)
		{
			return
		}

		console.log("OBULL2 ")
		
		const knife = this.knives.get(x, y, 'knife') as Phaser.Physics.Arcade.Image
		if (!knife)
		{
			return
		}

		console.log("OBULL3 ")
		

		const parts = this.anims.currentAnim.key.split('-')

		// const direction = parts[2]

		const vec = new Phaser.Math.Vector2(0, 0)

		vec.x = vx

		vec.y = vy
		vec.x = 1
		vec.y = -1


		const angle = vec.angle()

		knife.setActive(true)
		knife.setVisible(true)

		knife.setRotation(angle)

		knife.x += vec.x * 16
		knife.y += vec.y * 16

		knife.setVelocity(vec.x * 300, vec.y * 300)

		this.lastvx = vec.x;
		this.lastvy = vec.y;

		// this.scene.ss.play();		
		return 0
        
	}






	


	private throwKnife(bitdown: number, newbits: number)
	{




		if (!this.knives)
		{
			return
		}


		if (this.sprogress > 0.99) {

		console.log("BC = %d",this.shotcount)

		if (this.shotcount > 2)
		{
			return
		}
		else this.shotcount += 1

		const knife = this.knives.get(this.x, this.y, 'knife') as Phaser.Physics.Arcade.Image
		if (!knife)
		{
			return
		}

	

		const parts = this.anims.currentAnim.key.split('-')
		const direction = parts[2]

		const vec = new Phaser.Math.Vector2(0, 0)


		if (this.gameplay_option === 1) {
		switch (direction)
		{
			case 'up':
				vec.y = -1
				break

			case 'down':
				vec.y = 1
				break

			default:
			case 'side':
				if (this.scaleX < 0)
				{
					vec.x = -1
				}
				else
				{
					vec.x = 1
				}
				break
		}
}

if (this.gameplay_option === 2) {
	console.log("ststststst bitdown ",bitdown)
			//let bitdown = (leftDown << 3)  + (rightDown << 2) + (upDown << 1) + downDown
	if ((bitdown&2)>0) // up
	{
		if ((newbits&1)>0) {
			vec.y = 1 
			}
			else {
			vec.y = -1
			}
	

//		vec.y = -1
	}

	if ((bitdown&1)>0) // down
	{
		if ((newbits&2)>0) {
		vec.y = -1 
		}
		else {
		vec.y = 1
		}
	}

	if (((bitdown&8)>0) && ((bitdown&4)>0)) {
		switch (direction)
		{
			case 'side':
			if (this.scaleX < 0)
				{
					vec.x = -1
				}
				else
				{
					vec.x = 1
				}

		}
	}
	else if ((bitdown&8)>0) //left 
		{
			vec.x = -1

		}
	else if ((bitdown&4)>0) // right
		{
		vec.x = 1
		}

		if ((vec.x === 0) && (vec.y === 0))
{
			switch (direction)
		{
			case 'up':
				vec.y = -1
				break


			case 'down':
				vec.y = 1
				break

			default:
			case 'side':
				if (this.scaleX < 0)
				{
					vec.x = -1
				}
				else
				{
					vec.x = 1
				}
	
		}
}

	}

		// for all options

		const angle = vec.angle()

// console.log("BCBSZ ="+knife.body?.width+" h="+knife.body?.height)

		
		knife.setData('playbul', 1)
		knife.playbul = 1

		// XXX change for a change
		knife.x += vec.x * 1 // 16
		knife.y += vec.y * 1 // 16

		knife.setVelocity(vec.x * 300, vec.y * 300) // was 300
		knife.setRotation(angle)
		knife.body?.setSize(6,6,true)


		// knife.setVelocity(vec.x * 250, vec.y * 250)

		// console.log("PX="+this.x+" KX="+ knife.x+" PY="+this.y+" KY="+knife.y+" VX="+vec.x+" VY="+vec.y)
		this.lastvx = vec.x;
		this.lastvy = vec.y;

		//console.log("BCBSZ ="+knife.body?.width+" h="+knife.body?.height)
		//knife.setBodySize(13,6)
		// knife.setCollideWorldBounds(true)
		knife.setActive(true)
		knife.setVisible(true)

		

		// this.scene.ss.play();		
		return 5
	}
        
	}

	preUpdate(t: number, dt: number)
	{
		super.preUpdate(t, dt)

		switch (this.healthState)
		{
			case HealthState.IDLE:
				break

			case HealthState.DAMAGE:
				this.damageTime += dt
				if (this.damageTime >= 250)
				{
					this.healthState = HealthState.IDLE
					this.setTint(0xffffff)
					this.damageTime = 0
				}
				break
		}
	}



	initAnim(i: number) {
		if (i == 1) {		
			this.player_idle_down = 'carabinieri-idle-down'
			this.player_run_down = 'carabinieri-run-down'
			this.player_run_up = 'carabinieri-run-up'
			this.player_run_side = 'carabinieri-run-side'			
		} else if (i == 2) {
			this.player_idle_down = 'prezza-idle-down'
			this.player_run_down = 'prezza-run-down'
			this.player_run_up = 'prezza-run-up'
			this.player_run_side = 'prezza-run-side'			
		} else if (i == 3) {
			this.player_idle_down = 'ashi-idle-down'
			this.player_run_down = 'ashi-run-down'
			this.player_run_up = 'ashi-run-up'
			this.player_run_side = 'ashi-run-side'			
		} else if (i == 4) {
			this.player_idle_down = 'bull-idle-down'
			this.player_run_down = 'bull-run-down'
			this.player_run_up = 'bull-run-up'
			this.player_run_side = 'bull-run-side'			
		} else if (i == 5) {
			this.player_idle_down = 'wizard-idle-down'
			this.player_run_down = 'wizard-run-down'
			this.player_run_up = 'wizard-run-up'
			this.player_run_side = 'wizard-run-side'			
		} else if (i == 6) {
			this.player_idle_down = 'ape-idle-down'
			this.player_run_down = 'ape-run-down'
			this.player_run_up = 'ape-run-up'
			this.player_run_side = 'ape-run-side'			
		} else if (i == 7) {
			this.player_idle_down = 'bear-idle-down'
			this.player_run_down = 'bear-run-down'
			this.player_run_up = 'bear-run-up'
			this.player_run_side = 'bear-run-side'			
		} else if (i == 8) {
			this.player_idle_down = 'hedgefund-idle-down'
			this.player_run_down = 'hedgefund-run-down'
			this.player_run_up = 'hedgefund-run-up'
			this.player_run_side = 'hedgefund-run-side'			
		} else if (i == 9) {
			this.player_idle_down = 'pdoge-idle-down'
			this.player_run_down = 'pdoge-run-down'
			this.player_run_up = 'pdoge-run-up'
			this.player_run_side = 'pdoge-run-side'			
		} else if (i == 10) {
			this.player_idle_down = 'bdsm-idle-down'
			this.player_run_down = 'bdsm-run-down'
			this.player_run_up = 'bdsm-run-up'
			this.player_run_side = 'bdsm-run-side'			
		} else if (i == 11) {
			this.player_idle_down = 'karateka-idle-down'
			this.player_run_down = 'karateka-run-down'
			this.player_run_up = 'karateka-run-up'
			this.player_run_side = 'karateka-run-side'			
		} else if (i == 12) {
			this.player_idle_down = 'ninja-idle-down'
			this.player_run_down = 'ninja-run-down'
			this.player_run_up = 'ninja-run-up'
			this.player_run_side = 'ninja-run-side'				
		} else if (i == 13) {
			this.player_idle_down = 'amazonian-idle-down'
			this.player_run_down = 'amazonian-run-down'
			this.player_run_up = 'amazonian-run-up'
			this.player_run_side = 'amazonian-run-side'		
		} else if (i == 14) {
			this.player_idle_down = 'area51-idle-down'
			this.player_run_down = 'area51-run-down'
			this.player_run_up = 'area51-run-up'
			this.player_run_side = 'area51-run-side'		
		} else if (i == 15) {
			this.player_idle_down = 'banana-idle-down'
			this.player_run_down = 'banana-run-down'
			this.player_run_up = 'banana-run-up'
			this.player_run_side = 'banana-run-side'		
		} else if (i == 16) {
			this.player_idle_down = 'dragon-idle-down'
			this.player_run_down = 'dragon-run-down'
			this.player_run_up = 'dragon-run-up'
			this.player_run_side = 'dragon-run-side'		
		} else if (i == 17) {
			this.player_idle_down = 'eagle-idle-down'
			this.player_run_down = 'eagle-run-down'
			this.player_run_up = 'eagle-run-up'
			this.player_run_side = 'eagle-run-side'		
		} else if (i == 18) {
			this.player_idle_down = 'knight-idle-down'
			this.player_run_down = 'knight-run-down'
			this.player_run_up = 'knight-run-up'
			this.player_run_side = 'knight-run-side'		
		} else if (i == 19) {
			this.player_idle_down = 'spaceman-idle-down'
			this.player_run_down = 'spaceman-run-down'
			this.player_run_up = 'spaceman-run-up'
			this.player_run_side = 'spaceman-run-side'		
		} else if (i == 20) {
			this.player_idle_down = 'tiger-idle-down'
			this.player_run_down = 'tiger-run-down'
			this.player_run_up = 'tiger-run-up'
			this.player_run_side = 'tiger-run-side'			
		}
		this.anims.play(this.player_idle_down)

	}

	onDeathTimedEvent ()
    {
        
    }



	

	testHitFlight(vx,vy) {
		this.in_hit_flight = 1
		// if ((vx != 0) && (vy != 0)) {
		// 	if (Math.floor(Math.random() * 2) == 0) {
		// 		vx = 0
		// 	}
		// 	else {
		// 		vy = 0
		// 	}
		// }
		// this.setVelocity(vx*1.5 , vy*1.5)
		// this.flightCtr = 5
		this.setVelocity(vx*2 , vy*2)
		this.flightCtr = 6


	}

	recoverFromFlight() {
		this.in_hit_flight = 0
		this.setVelocity(0 , 0)
		this.angle = 0.0
	}



	update(cursors: Phaser.Types.Input.Keyboard.CursorKeys, cursors2: Phaser.Types.Input.Keyboard.CursorKeys,  Clicking:boolean)
	{


		let rx = 0
		

		//if (this.healthState === HealthState.DAMAGE
		//	|| this.healthState === HealthState.DEAD
		//)
		//{
		//	return
		//}

		if (this.in_hit_flight == 0) {

		if (!cursors)
		{
			return
		}



		let leftDown = Number(cursors.left?.isDown || (cursors2!=undefined && cursors2.left!?.isDown)) 
		let rightDown = Number(cursors.right?.isDown || (cursors2!=undefined && cursors2.right!?.isDown))
		let upDown = Number(cursors.up?.isDown || (cursors2!=undefined && cursors2.up!?.isDown))
		let downDown = Number(cursors.down?.isDown || (cursors2!=undefined && cursors2.down!?.isDown))

		if ((this.scene.firstTele === 1) || (this.scene.noMove === 1)) {
			leftDown = Number(0)
			rightDown = Number(0)
			upDown = Number(0)
			downDown = Number(0)
	
		}


		let sumdown = leftDown + rightDown + upDown + downDown
		let bitdown = (leftDown << 3)  + (rightDown << 2) + (upDown << 1) + downDown
		let kbitdown = bitdown

		// if (Phaser.Input.Keyboard.JustDown(cursors.space!) || (cursors2!=undefined && Phaser.Input.Keyboard.JustDown(cursors2.space!)))
		// {						
		// 	rx = this.throwKnife(bitdown)
		// 	// there was a break here which was causing
		// }

		const speed = 100
		let xs = 0
		let ys = 0




		
//		console.log("SUMDOWN"+sumdown)
//		console.log("BITDOWN"+bitdown)
		let newbits = (this.lastbitdown ^ 15) & bitdown
//		console.log("NEWDOWN"+newbits)

		if (this.lastsumdown < sumdown) { // more keys
			this.downhistory[sumdown] = bitdown
			this.newhistory[sumdown] = newbits
			const diff = sumdown - this.lastsumdown
			if (diff == 2) {
				this.downhistory[sumdown-1] = this.downhistory[sumdown-2]
			} 
			else if (diff == 3) {
				this.downhistory[sumdown-1] = this.downhistory[sumdown-3]
				this.downhistory[sumdown-2] = this.downhistory[sumdown-3]

			} 

		}

		
		if ((this.lastsumdown == sumdown) && (newbits > 0)) { // more keys
			this.downhistory[sumdown] = bitdown
			this.newhistory[sumdown] = newbits
		}
		

		const LEFT_HAND_BIT = 8
		const RIGHT_HAND_BIT = 4
		const UP_HAND_BIT = 2
		const DOWN_HAND_BIT = 1
		
		let lekky = 0
		// get last new key

		let realnewbits = newbits

		if (newbits < 1) {
			newbits = this.newhistory[sumdown]
			bitdown = this.downhistory[sumdown]
			lekky = 1

		} 




	if (this.gameplay_option === 1) {

			if ((newbits & LEFT_HAND_BIT) > 0) 
			{
				// this.anims.play('faune-run-side', true)
				// this.anims.play('prezza-run-side', true)
				// this.anims.play('carabinieri-run-side',true)
				// this.anims.play(this.player_run_side,true)
				
				this.anims.play(this.player_run_side, true)
	
				this.setVelocity(-speed, 0)
				//this.balVal.setVelocity(-speed, 0)
	
				// xs = -2
				this.scaleX = -1
				this.body.offset.x = 24
			}
			else if ((newbits & RIGHT_HAND_BIT)>0)
			{
	//			this.anims.play('faune-run-side', true)
				//this.anims.play('prezza-run-side', true)
				//this.anims.play('carabinieri-run-side',true)
				this.anims.play(this.player_run_side,true)
	
				//this.balVal.setVelocity(speed, 0)
				
				this.setVelocity(speed, 0)
				// xs = 2
				this.scaleX = 1
				this.body.offset.x = 8
			}
			else if ((newbits & UP_HAND_BIT)>0)
			{
				// this.anims.play('faune-run-up', true)
				// this.anims.play('prezza-run-up', true)
				//this.anims.play('carabinieri-run-up', true)
				this.anims.play(this.player_run_up, true)
				// ys = -2
				this.setVelocity(0, -speed)
				//this.balVal.setVelocity(0, -speed)
			}
			else if ((newbits & DOWN_HAND_BIT)>0)
			{
	//			this.anims.play('faune-run-down', true)
				//this.anims.play('prezza-run-down', true)
				//this.anims.play('carabinieri-run-down', true)
				this.anims.play(this.player_run_down, true)
	
				this.setVelocity(0, speed)
				//this.balVal.setVelocity(0, speed)
				// ys = 2
			}

}

	let upsp = 0
	let lrsp = 0



	if (this.gameplay_option === 2) {


	//let bitdown = (leftDown << 3)  + (rightDown << 2) + (upDown << 1) + downDown
	if ((bitdown&2)>0) // up
		{
			// this.anims.play('faune-run-up', true)
			// this.anims.play('prezza-run-up', true)
			//this.anims.play('carabinieri-run-up', true)
			if ((lekky == 0) && ((newbits & (LEFT_HAND_BIT+RIGHT_HAND_BIT)) < 1) && 
				(newbits >0)) {
					this.anims.play(this.player_run_up, true)
			}
			// ys = -2
			//this.setVelocity(0, -speed)
			upsp = -speed
			//this.balVal.setVelocity(0, -speed)
		}
		if ((bitdown&1)>0) // down
		{
//			this.anims.play('faune-run-down', true)
			//this.anims.play('prezza-run-down', true)
			//this.anims.play('carabinieri-run-down', true)
			if ((lekky == 0) && ((newbits & (LEFT_HAND_BIT+RIGHT_HAND_BIT)) < 1) && 
			(newbits >0)) {
				if ((newbits&2) > 0) {
					this.anims.play(this.player_run_up, true)		
				}
				else {
				this.anims.play(this.player_run_down, true)
				}
		}

			upsp = speed
			if ((newbits&2) > 0) {
				upsp = -speed
			}
			// this.setVelocity(0, speed)
			//this.balVal.setVelocity(0, speed)
			// ys = 2
		}
		



			if (((newbits&8)>0) || (((newbits&12)<1) && ((bitdown&8)>0)))//left 
			{
				// this.anims.play('faune-run-side', true)
				// this.anims.play('prezza-run-side', true)
				// this.anims.play('carabinieri-run-side',true)
				// this.anims.play(this.player_run_side,true)
				this.anims.play(this.player_run_side, true)	
				this.setVelocity(-speed, upsp)
	
				//this.balVal.setVelocity(-speed, 0)
				lrsp = -speed
				// xs = -2
				
				this.body.offset.x = 24
				// if (((realnewbits&8) > 0) && (this.scaleX > -1)) {
				// 	this.setX(this.x+16)
				// 	this.nameText.setX(this.nameText.x+16)
				// 	this.hudFlag.setX(this.hudFlag.x+16)
				// }
				this.scaleX = -1
				
			}
			else if (((newbits&4)>0) || (((newbits&12)<1) && ((bitdown&4)>0)))// right
			{
	//			this.anims.play('faune-run-side', true)
				//this.anims.play('prezza-run-side', true)
				//this.anims.play('carabinieri-run-side',true)
				this.anims.play(this.player_run_side,true)
	
				//this.balVal.setVelocity(speed, 0)
				lrsp = speed
				this.setVelocity(speed, upsp)
				// xs = 2
				// this.scaleX = 1
				this.body.offset.x = 8

				
				// if (((realnewbits&4) > 0) && (this.scaleX < 1)) {
				// 	this.setX(this.x-16)
				// 	this.nameText.setX(this.nameText.x-16)
				// 	this.hudFlag.setX(this.hudFlag.x-16)

				// }
				this.scaleX = 1



				//this.setX(this.x+8)

			} else {
				this.setVelocity(0, upsp)
			}
			
			
		}

		if ((sumdown == 1) && (downDown > 0))  
		{
			this.anims.play(this.player_run_down, true)	
		}



		
		if ((sumdown == 1) && (upDown > 0))  
		{
			this.anims.play(this.player_run_up, true)	

		}


		
		// all options for now
		if (sumdown == 0) 
		{
			const parts = this.anims.currentAnim.key.split('-')
			parts[1] = 'idle'
			this.anims.play(parts.join('-'))
			this.setVelocity(0, 0)
			//this.balVal.setVelocity(0, 0)
		}



		if (Phaser.Input.Keyboard.JustDown(cursors.space!) || Clicking || (cursors2!=undefined && cursors2.space!=undefined && Phaser.Input.Keyboard.JustDown(cursors2.space!)))
		{						
			if ((this.scene.is_in_intro === false) && (this.scene.no_shoot_end === false)) { rx = this.throwKnife(bitdown, newbits); }
			// there was a break here which was causing
		}


	

		this.lastleftDown = leftDown
		this.lastrightDown = rightDown
		this.lastupDown = upDown
		this.lastdownDown = downDown

		this.lastbitdown = bitdown
		this.lastsumdown = sumdown

		// XXXX this.balVal.setPosition(this.x - 16, this.y - 28)
		// console.log("xs="+xs+" ys="+ys+" xsd="+xs+" ysd="+ys)

		//this.alignBody(this.balVal, 400, 600, Phaser.Display.Align.BOTTOM_CENTER);
		
		// this.balVal.update()
		// this.balVal.setOrigin(0.8, 0.5);


if (this.head_up_option == 2) {

		Phaser.Display.Bounds.SetBottom(this.nameText, (this.body.top-4));
		Phaser.Display.Bounds.SetCenterX(this.nameText, (this.body.center.x));
		this.nameText.update()

		Phaser.Display.Bounds.SetBottom(this.hudFlag, (this.body.top-16));
		Phaser.Display.Bounds.SetCenterX(this.hudFlag, (this.body.center.x));
		this.hudFlag.update()

		Phaser.Display.Bounds.SetBottom(this.bpg, (this.body.top+38));
		Phaser.Display.Bounds.SetCenterX(this.bpg, (this.body.center.x));

		Phaser.Display.Bounds.SetBottom(this.bpgend, (this.body.top+38));
		Phaser.Display.Bounds.SetCenterX(this.bpgend, (this.body.center.x));
	// XXX This is how to bring things on top!
		 if (this.firstrun == 0) {
		 	this.scene.children.bringToTop(this.bpgend);
		 }
		 this.bpgend.update()
		 this.bpg.update()
}
else if  (this.head_up_option == 1) {
		Phaser.Display.Bounds.SetBottom(this.balVal, (this.body.top-6));
		Phaser.Display.Bounds.SetCenterX(this.balVal, (this.body.center.x));
		this.balVal.update()
}


	this.firstrun = 1

	if (rx===undefined) return(0)
		else return(rx)

} // not in_hit_flight
else {
	console.log("in is_hit_flight")
	this.angle += 10
	this.flightCtr = this.flightCtr - 1
	if (this.flightCtr < 1) { 
		this.recoverFromFlight();
		this.scene.cancelObjFx(this)
	}
	if (this.head_up_option == 2) {

		Phaser.Display.Bounds.SetBottom(this.nameText, (this.body.top-4));
		Phaser.Display.Bounds.SetCenterX(this.nameText, (this.body.center.x));
		this.nameText.update()

		Phaser.Display.Bounds.SetBottom(this.hudFlag, (this.body.top-16));
		Phaser.Display.Bounds.SetCenterX(this.hudFlag, (this.body.center.x));
		this.hudFlag.update()
	}

	else if  (this.head_up_option == 1) {
		Phaser.Display.Bounds.SetBottom(this.balVal, (this.body.top-6));
		Phaser.Display.Bounds.SetCenterX(this.balVal, (this.body.center.x));
		this.balVal.update()
}

}

	} 

}

Phaser.GameObjects.GameObjectFactory.register('faune', function (this: Phaser.GameObjects.GameObjectFactory, x: number, y: number, texture: string, frame?: string | number) {
	var sprite = new Faune(this.scene, x, y, texture, frame)


	this.displayList.add(sprite)
	this.updateList.add(sprite)

	this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)

	sprite.body?.setSize(sprite.width * 0.5, sprite.height * 0.8)
	sprite.setInteractive(new Phaser.Geom.Rectangle(0, 0, 16, 16))

	// this.scene.input.setHitArea(sprite,new Phaser.Geom.Rectangle(0, 0, 16, 16))

	return sprite
})